import { useCallback, useEffect, useRef, useState } from "react";
import Zendesk from "react-zendesk";
import { useAuth } from "../contexts/AuthContext";
import { zendeskApiKey } from "../utils/constants";

const zEButtonSelector = 'button[aria-label="Open messaging window"]';

const ZendeskChat = () => {
  const [position, setPosition] = useState({ bottom: 20, right: 20 });
  const [isDragging, setIsDragging] = useState(false);
  const { isAuthenticated } = useAuth();
  const launcher = useRef<HTMLIFrameElement | null>(null);

  const handleMouseMove = useCallback(
    (e: any) => {
      if (launcher.current) {
        const element = launcher.current;
        const rect = element.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        setPosition((prev) => {
          const newBottom = Math.min(
            Math.max(prev.bottom - e.movementY, 0),
            windowHeight - rect.height,
          );
          const newRight = Math.min(
            Math.max(prev.right - e.movementX, 0),
            windowWidth - rect.width,
          );
          return { bottom: newBottom, right: newRight };
        });
      }
    },
    [launcher, setPosition],
  );

  const handleMouseUp = useCallback(
    () => setIsDragging(false),
    [setIsDragging],
  );

  const handleMouseDown = useCallback(
    () => setIsDragging(true),
    [setIsDragging],
  );

  useEffect(() => {
    if (launcher.current) {
      launcher.current.style.visibility = isAuthenticated
        ? "visible"
        : "hidden";
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const element = launcher.current as HTMLIFrameElement;
    const iframeDoc =
      element?.contentDocument || element?.contentWindow?.document;
    if (iframeDoc) {
      if (isDragging) {
        iframeDoc.addEventListener("mousemove", handleMouseMove);
        iframeDoc.addEventListener("mouseup", handleMouseUp);
      } else {
        iframeDoc.removeEventListener("mousemove", handleMouseMove);
        iframeDoc.removeEventListener("mouseup", handleMouseUp);
      }
    }

    // Clean up event listeners
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.querySelector("#launcher") as HTMLIFrameElement;
      if (element) {
        launcher.current = element;
        setPosition({ bottom: 20, right: 20 });
        clearInterval(interval);
      }
    }, 500);
  }, []);

  useEffect(() => {
    const element = launcher.current as HTMLIFrameElement;
    if (element) {
      const iframeDoc =
        element?.contentDocument || element?.contentWindow?.document;

      if (iframeDoc) {
        const nestedButton = iframeDoc.querySelector(zEButtonSelector);

        element.style.position = "absolute";
        element.style.bottom = `${position.bottom}px`;
        element.style.right = `${position.right}px`;
        element.style.cursor = isDragging ? "grabbing" : "grab";

        if (nestedButton) {
          nestedButton.addEventListener("mousedown", handleMouseDown);
        }
      }
    }
  }, [position, isDragging, launcher.current]);

  return isAuthenticated ? <Zendesk zendeskKey={zendeskApiKey} /> : null;
};

export default ZendeskChat;
