import { useVirtualizer } from "@tanstack/react-virtual";
import React, {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface VirtualizedListProps<T> {
  items: T[];
  parentRef: RefObject<HTMLDivElement | null>;
  textSelector: string;
  getCopyText: (
    leftIndex: number,
    leftOffset: number,
    rightIndex: number,
    rightOffset: number,
  ) => string;
  estimateSize: (index: number) => number;
  onVisibleItemsChange: (startIndex: number, endIndex: number) => void;
  renderItem: (item: T) => ReactNode;
}

interface Range {
  startIndex: number;
  startOffset: number;
  endIndex: number;
  endOffset: number;
}

const getIndex = (element: HTMLElement | null): number => {
  if (!element) return 0;
  if (element.hasAttribute("data-index"))
    return parseInt(element.getAttribute("data-index") as string, 10);
  return getIndex(element.parentElement);
};

const VirtualizedList: React.FC<VirtualizedListProps<any>> = ({
  items,
  parentRef,
  textSelector,
  getCopyText,
  estimateSize,
  onVisibleItemsChange,
  renderItem,
}) => {
  const [selectedRange, setSelectedRange] = useState<Range | null>(null);
  const isProgrammaticSelect = useRef(false);
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize,
  });

  const virtualItems = virtualizer.getVirtualItems();

  useEffect(() => {
    virtualizer.measure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (virtualItems?.length && onVisibleItemsChange) {
      onVisibleItemsChange(
        virtualItems[0].index,
        virtualItems[virtualItems.length - 1].index,
      );
    }
  }, [onVisibleItemsChange, virtualItems]);

  useEffect(() => {
    const handleSelectionChange = () => {
      setSelectedRange((prev) => {
        const selection = window.getSelection();
        if (!selection || !selection.anchorNode || !selection.focusNode) {
          return prev;
        }
        // Empty state on outside selection
        if (!parentRef.current?.contains(selection.anchorNode.parentElement)) {
          return null;
        }
        const startIndex = getIndex(selection.anchorNode.parentElement);
        const startOffset = selection.anchorOffset;

        const endIndex = getIndex(selection.focusNode.parentElement);
        const endOffset = selection.focusOffset;

        // Don't change the state when the selection is done programmatically(e.g. on items scroll)
        if (isProgrammaticSelect.current) {
          isProgrammaticSelect.current = false;
          return prev;
        }

        // New selection
        if (!prev || (startIndex === endIndex && startOffset === endOffset)) {
          return {
            startIndex,
            startOffset,
            endIndex,
            endOffset,
          };
        }
        // Change only end position on continuous selection
        return { ...prev, endIndex, endOffset };
      });
    };
    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [parentRef]);

  const selectRange = useCallback(
    (selectedRange: Range | null, isProgrammatic?: boolean) => {
      if (!virtualItems?.length || !selectedRange) return;
      const { startIndex, startOffset, endIndex, endOffset } = selectedRange;

      // Selection is from top-left to bottom-right
      const toRight =
        endIndex > startIndex ||
        (endIndex === startIndex && endOffset > startOffset);

      // Reverse position if selection is from bottom-right to top-left
      const [leftIndex, leftOffset, rightIndex, rightOffset] = toRight
        ? [startIndex, startOffset, endIndex, endOffset]
        : [endIndex, endOffset, startIndex, startOffset];

      const firstItem = virtualItems[0];
      const lastItem = virtualItems[virtualItems.length - 1];

      const startElementIndex =
        firstItem.index > leftIndex ? firstItem.index : leftIndex;
      const endElementIndex =
        lastItem.index < rightIndex ? lastItem.index : rightIndex;

      const startElementText = parentRef.current?.querySelector(
        `[data-index="${startElementIndex}"] ${textSelector}`,
      )?.childNodes?.[0];
      const endElementText = parentRef.current?.querySelector(
        `[data-index="${endElementIndex}"] ${textSelector}`,
      )?.childNodes?.[0];

      const sel = window.getSelection();
      if (!sel) return;
      // Remove existing selection when elements are not more visible
      if (!startElementText || !endElementText) {
        sel.removeAllRanges();
        return;
      }
      const startElementOffset =
        startElementIndex === leftIndex ? leftOffset : 0;
      const endElementOffset =
        endElementIndex === rightIndex
          ? rightOffset
          : (endElementText.textContent?.length || 1) - 1;
      // Remove previous selection
      sel.removeAllRanges();
      // Mark selection as programmatic
      if (isProgrammatic) isProgrammaticSelect.current = true;
      // Keep previous selection direction
      if (toRight) {
        sel.setBaseAndExtent(
          startElementText,
          startElementOffset,
          endElementText,
          endElementOffset,
        );
      } else {
        sel.setBaseAndExtent(
          endElementText,
          endElementOffset,
          startElementText,
          startElementOffset,
        );
      }
    },
    [parentRef, textSelector, virtualItems],
  );

  // Programmatically select items on scroll
  useEffect(() => {
    selectRange(selectedRange, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRange]);

  useEffect(() => {
    // Handle copy functionality
    const handleCopy = (event: ClipboardEvent) => {
      if (!selectedRange) return;

      const { startIndex, startOffset, endIndex, endOffset } = selectedRange;
      const toRight =
        endIndex > startIndex ||
        (endIndex === startIndex && endOffset > startOffset);

      const [leftIndex, leftOffset, rightIndex, rightOffset] = toRight
        ? [startIndex, startOffset, endIndex, endOffset]
        : [endIndex, endOffset, startIndex, startOffset];

      event.preventDefault();
      event.clipboardData?.setData(
        "text/plain",
        getCopyText(leftIndex, leftOffset, rightIndex, rightOffset),
      );
    };

    // Handle Shift + Click functionality
    const handleMouseDown = (event: MouseEvent) => {
      if (!event.shiftKey || !selectedRange) return;
      const target = event.target as HTMLElement;
      if (!parentRef.current?.contains(target)) {
        console.log("shift click selection outside");
        return;
      }
      const index = getIndex(target);
      const aa = target.querySelector(textSelector);
      const endOffset =
        (aa || target)?.childNodes?.[0]?.textContent?.length || 0;
      const newRange = {
        ...selectedRange,
        endIndex: index,
        endOffset,
      };
      selectRange(newRange);
      setSelectedRange(newRange);
    };

    document.addEventListener("copy", handleCopy);
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [items, parentRef, selectedRange, textSelector, getCopyText, selectRange]);

  return (
    <div className='relative' style={{ height: virtualizer.getTotalSize() }}>
      <div
        className='absolute top-0 left-0 w-full'
        style={{
          transform: `translateY(${virtualItems[0]?.start || 0}px)`,
        }}
      >
        {virtualItems.map((virtualRow) => (
          <div
            key={virtualRow.key}
            data-index={virtualRow.index}
            ref={virtualizer.measureElement}
          >
            {renderItem(items[virtualRow.index])}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VirtualizedList;
