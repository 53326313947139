// Checkbox.tsx
import classNames from "classnames";
import { FC } from "react";

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
  tooltip: string;
  additionalClassNames?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  disabled,
  tooltip,
  additionalClassNames,
}) => {
  const checkboxClass = classNames(
    "form-checkbox h-5 w-5",
    disabled ? "" : "cursor-pointer",
    additionalClassNames,
  );
  return (
    <label
      className={`flex items-center space-x-2 ${
        disabled ? "opacity-50" : "cursor-pointer"
      }`}
      title={tooltip}
    >
      <input
        type='checkbox'
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        className={checkboxClass}
      />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
