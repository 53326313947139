import React from "react";
import { NavLink } from "react-router-dom";
import { EngineeringTicketType } from "../__generated__/graphql";
import LinearLogo from "../images/linearLogo.png";
import ZendeskLogo from "../images/zendeskLogo.png";
import { INTEGRATIONS } from "../utils/constants";

interface EngineeringTicketLinkProps {
  ticket: EngineeringTicketType;
}

const { LINEAR, ZENDESK } = INTEGRATIONS;

const INTEGRATION_ICONS = {
  [LINEAR]: LinearLogo,
  [ZENDESK]: ZendeskLogo,
};

const EngineeringTicketLink: React.FC<EngineeringTicketLinkProps> = ({
  ticket,
}) => {
  if (!ticket) return null;
  const { platformLink, provider, title } = ticket;

  return (
    <NavLink
      to={platformLink || ""}
      target='_blank'
      className='flex gap-1 items-center font-medium text-blue-600 dark:text-blue-500 hover:underline'
      onClick={(e) => e.stopPropagation()}
    >
      {title}
      <div className='flex p-[3px] min-h-[26px] h-[26px] min-w-[26px] w-[26px] border border-zinc-200 rounded-lg items-center justify-center'>
        <img src={INTEGRATION_ICONS[provider]} alt='Platform Logo' />
      </div>
    </NavLink>
  );
};

export default EngineeringTicketLink;
