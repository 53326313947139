import { useMutation, useQuery } from "@apollo/client";
import {
  DISMISS_ANNOUNCEMENT,
  GET_LATEST_BANNER_ANNOUNCEMENT,
} from "../graphql/announcementsQueries";

const BannerFromBackend = () => {
  const { data, refetch } = useQuery(GET_LATEST_BANNER_ANNOUNCEMENT);
  const [dismissAnnouncement] = useMutation(DISMISS_ANNOUNCEMENT);

  const handleDismiss = async (id: string) => {
    await dismissAnnouncement({
      variables: { announcementId: parseInt(id, 10) },
    });
    refetch(); // Refetch to update the view after dismissal
  };

  // if (loading) return <div>Loading...</div>;
  if (!data || !data.latestBannerAnnouncement) return null;

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#FFFAA0",
        paddingBlock: "10px",
        paddingInline: "20px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: data.latestBannerAnnouncement.content,
        }}
      />
      <button
        onClick={() => handleDismiss(data.latestBannerAnnouncement.id)}
        style={{
          padding: "5px 10px",
          cursor: "pointer",
          backgroundColor: "lightgray",
          borderRadius: "5px",
        }}
      >
        Dismiss
      </button>
    </div>
  );
};

export default BannerFromBackend;
