import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  RecordingSessionQueryResultsGroupedType,
  RecordingSessionType,
} from "../__generated__/graphql";
import ApplicationPage from "../components/ApplicationPage";
import FilterList from "../components/FilterList";
import GroupedRecordingsTimeline from "../components/GroupedRecordingsTimeline";
import LoadingAnimation from "../components/LoadingAnimation";
import RRWebPlayerWithTriage from "../components/RRWebPlayerWithTriage";
import RecordingsSearchAndFilters from "../components/Search/RecordingsSearchAndFilters";
import { WebPlayerProvider } from "../contexts/WebPlayerContext";
import useContainerWidth from "../hooks/useContainerWidth";
import useUnzippedEvents from "../hooks/useUnzippedEvents";
import { RECORDINGS_EMPTY_FILTERS } from "../utils/constants";
import { DeviceType, RecordingsFilters } from "../utils/interfaces";

const RECORDINGS_PER_PAGE = 10;
const PLAYER_HEIGHT_PX = 462;
const RECORDING_LOOKBACK_DAYS = 14;

const ReplaysWithSearchAndExaminePage = () => {
  const rrwebPlayerRef = useRef<any>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRecording, setSelectedRecording] =
    useState<RecordingSessionType | null>(null);
  const pageInQuery = Number(searchParams.get("page"));
  const [page, setPage] = useState(pageInQuery || 1);
  const [recordings, setRecordings] = useState<
    RecordingSessionQueryResultsGroupedType[]
  >([]);
  const [selectedRecordings, setSelectedRecordings] =
    useState<RecordingSessionQueryResultsGroupedType | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);
  const [selectedDeviceRecordings, setSelectedDeviceRecordings] = useState<
    RecordingSessionType[] | null
  >(null);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  // Select recording after fetching the list. -1 - select last item, 1 select first item, 0 don't select
  const [selectRecordingAfterFetch, setSelectRecordingAfterFetch] = useState(0);
  const [filters, setFilters] = useState<RecordingsFilters>(
    RECORDINGS_EMPTY_FILTERS,
  );
  const [timeline, setTimeline] = useState<[number, number]>([0, 0]);

  const {
    unzippedEvents,
    timelineEvents,
    eventsError,
    loadingEvents,
    setUnzippedEvents,
    setTimelineEvents,
  } = useUnzippedEvents(selectedRecording?.id);
  const { containerRef, containerWidth } = useContainerWidth();

  const handleSelectRecording = (recording: RecordingSessionType) => {
    if (selectedRecording?.id === recording.id) {
      // Same recording selected, seek back to the start and keep playing
      rrwebPlayerRef.current?.resetPlayer();
    } else {
      setUnzippedEvents(null); // Reset events to force RRWebPlayer re-initialization
      setTimelineEvents([]);
      setSelectedRecording(recording);
    }
  };

  const handleRecordingGroupChange = (
    recordingGroup: RecordingSessionQueryResultsGroupedType,
  ) => {
    setSelectedRecordings(recordingGroup);
    const deviceRecordings = recordingGroup.recordings.filter(
      selectedDevice === "Mobile"
        ? (r) => r.metadata?.device?.isMobile
        : (r) => r.metadata?.device?.isDesktop,
    );
    setSelectedDeviceRecordings(deviceRecordings);
    handleSelectRecording(deviceRecordings[0]);
  };

  const handleSearchResults = (results: any[]) => {
    const endDate = Date.now();
    setTimeline([
      endDate - RECORDING_LOOKBACK_DAYS * 24 * 3600 * 1000,
      endDate,
    ]);
    setRecordings(results);
    if (results.length && selectRecordingAfterFetch) {
      setSelectRecordingAfterFetch(0);
      handleRecordingGroupChange(
        results[selectRecordingAfterFetch > 0 ? 0 : results.length - 1],
      );
    }
  };

  const handleTotalItemsCount = (count: number) => {
    setTotalItemsCount(count); // Update the total items count
  };

  const totalPages = Math.ceil(totalItemsCount / RECORDINGS_PER_PAGE);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setSearchParams((params) => {
      params.set("page", `${newPage}`);
      return params;
    });
  };

  useEffect(() => {
    // Calculate the new total pages based on the updated totalItemsCount
    const newTotalPages = Math.ceil(totalItemsCount / RECORDINGS_PER_PAGE);

    // If the current page exceeds the new total pages, set the page to the new max page
    if (page > newTotalPages && newTotalPages > 0) {
      setPage(newTotalPages);
      setSearchParams((params) => {
        params.set("page", `${newTotalPages}`);
        return params;
      });
    }
  }, [page, setSearchParams, totalItemsCount]);

  const currentRecordingIndex = recordings.findIndex(
    ({ userId }) => userId === selectedRecordings?.userId,
  );
  const isFirstRecording = currentRecordingIndex === 0;
  const isLastRecording = currentRecordingIndex === recordings.length - 1;

  const handleBackwardRecording = () => {
    if (isFirstRecording) {
      setSelectRecordingAfterFetch(-1);
      handlePageChange(page - 1);
    } else {
      handleRecordingGroupChange(recordings[currentRecordingIndex - 1]);
    }
  };

  const handleForwardRecording = () => {
    if (isLastRecording) {
      setSelectRecordingAfterFetch(1);
      handlePageChange(page + 1);
    } else {
      handleRecordingGroupChange(recordings[currentRecordingIndex + 1]);
    }
  };

  const backwardRecordingDisabled = isFirstRecording && page === 1;
  const forwardRecordingDisabled = isLastRecording && page === totalPages;

  return (
    <ApplicationPage
      pageName='Recordings'
      helpLink='https://docs.sailfishqa.com/'
      helpTooltip='Click here to learn about Recordings.'
    >
      <div className='mb-8'>
        <WebPlayerProvider>
          <div ref={containerRef}>
            <div className='h-full'>
              {selectedRecording && (
                <RRWebPlayerWithTriage
                  id={selectedRecording?.id}
                  selectedRecording={selectedRecording}
                  ref={rrwebPlayerRef}
                  events={unzippedEvents}
                  timelineEvents={timelineEvents}
                  highlightedSections={[]}
                  width={containerWidth - 16}
                  height={PLAYER_HEIGHT_PX}
                  containerWidth={containerWidth}
                  eventsError={eventsError}
                  loadingEvents={loadingEvents}
                  handleBackwardRecording={handleBackwardRecording}
                  handleForwardRecording={handleForwardRecording}
                  backwardRecordingDisabled={backwardRecordingDisabled}
                  forwardRecordingDisabled={forwardRecordingDisabled}
                  handleSelectRecording={handleSelectRecording}
                  selectedRecordings={selectedRecordings}
                  timeline={timeline}
                  selectedDevice={selectedDevice}
                  selectedDeviceRecordings={selectedDeviceRecordings}
                  onPlay={(device, deviceRecordings) => {
                    setSelectedDeviceRecordings(deviceRecordings);
                    handleSelectRecording(deviceRecordings[0]);
                    setSelectedDevice(device);
                  }}
                />
              )}
            </div>
          </div>
        </WebPlayerProvider>
      </div>
      <div className='flex pb-4'>
        <div className='w-48 min-w-48 border-r border-zinc-200 pr-4'>
          <RecordingsSearchAndFilters
            entity='Recordings'
            limit={RECORDINGS_PER_PAGE}
            offset={(page - 1) * RECORDINGS_PER_PAGE}
            onSearchResults={handleSearchResults}
            setTotalItemsCount={handleTotalItemsCount}
            onLoadingChange={setLoading}
            orderBy='-start_timestamp'
            filters={filters}
            setFilters={setFilters}
          />
        </div>
        <div className='flex flex-col min-w-0 flex-1 ml-4 gap-2'>
          <FilterList
            filters={filters}
            filtersLabels={{ pageVisitUrls: "URL", devices: "Device" }}
            onRemove={(key, value) =>
              setFilters((prev) => ({
                ...prev,
                [key]: prev[key].filter((v) => v !== value),
              }))
            }
          />
          {loading ? (
            <LoadingAnimation text='Loading...' />
          ) : (
            <div className='flex flex-col gap-3 text-xs'>
              {recordings.map((r) => (
                <div
                  key={r.userId}
                  className='flex gap-2 border-b border-slate-300 pb-3'
                >
                  <p className='w-28 truncate font-medium'>{r.userId}</p>
                  <GroupedRecordingsTimeline
                    recordings={r.recordings}
                    selected={
                      selectedRecordings?.userId === r.userId && selectedDevice
                    }
                    timeline={timeline}
                    onPlay={(device, deviceRecordings) => {
                      setSelectedRecordings(r);
                      setSelectedDeviceRecordings(deviceRecordings);
                      handleSelectRecording(deviceRecordings[0]);
                      setSelectedDevice(device);
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </ApplicationPage>
  );
};

export default ReplaysWithSearchAndExaminePage;
