import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useAddGitHubIntegrationMutation } from "../../../__generated__/graphql";
import { useCompany } from "../../../contexts/CompanyContext";
import { INTEGRATIONS } from "../../../utils/constants";
import LoadingAnimation from "../../LoadingAnimation";

const { GITHUB, GITHUB_REDIRECT_URI, GITHUB_INTEGRATIONS_PATH } = INTEGRATIONS;

const IntegrationsGitHub = () => {
  const { selectedCompany } = useCompany();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const installationId = searchParams.get("installation_id");
  const setupAction = searchParams.get("setup_action");

  const [createIntegration] = useAddGitHubIntegrationMutation();

  useEffect(() => {
    const runMutation = async () => {
      if (installationId && setupAction) {
        try {
          await createIntegration({
            variables: {
              installationId,
              setupAction,
              companyId: selectedCompany
                ? parseInt(selectedCompany.value, 10)
                : undefined,
            },
          });
        } catch (error) {
          console.error("Error Integrating GitHub:", error);
        } finally {
          navigate(GITHUB_INTEGRATIONS_PATH);
        }
      }
    };

    runMutation();
  }, [installationId, createIntegration, selectedCompany, navigate]);

  // If "code" is not present, redirect back to the integrations page.
  return installationId ? (
    <LoadingAnimation text='Finalizing GitHub Integration...' />
  ) : (
    <Navigate to={GITHUB_INTEGRATIONS_PATH} />
  );
};

export default IntegrationsGitHub;
