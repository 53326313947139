import Tooltip from "@mui/material/Tooltip";
import React, {
  Dispatch,
  RefCallback,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RecordingSessionType } from "../__generated__/graphql";

const DRAG_HANDLE = {
  LEFT: "left",
  RIGHT: "right",
  REGION: "region",
} as const;

type DragHandleType = (typeof DRAG_HANDLE)[keyof typeof DRAG_HANDLE];

interface RecordingsTimelineProps {
  contentRef: RefCallback<HTMLDivElement | null>;
  currentPosition?: number;
  currentSection?: [number, number] | null;
  displayOnly?: boolean;
  hoverTime?: number | null;
  isDraggable?: boolean;
  isMoving?: boolean;
  offset: number;
  recordings: RecordingSessionType[];
  scale: number;
  selectedRegion?: Region | null;
  timeline: [number, number];
  onTimelineClick?: (recording: RecordingSessionType, time: number) => void;
  setSelectedRegion?: Dispatch<SetStateAction<Region | null>>;
}

export interface Region {
  start: number;
  end: number;
}

const RecordingsTimeline: React.FC<RecordingsTimelineProps> = ({
  contentRef,
  currentPosition,
  currentSection,
  displayOnly,
  hoverTime,
  isDraggable,
  isMoving,
  offset,
  recordings,
  scale,
  selectedRegion,
  timeline,
  onTimelineClick,
  setSelectedRegion,
}) => {
  const recordingsRef = useRef<(HTMLDivElement | null)[]>([]);
  const innerContentRef = useRef<HTMLDivElement | null>(null);

  const startXRef = useRef(0);
  const initialRegionRef = useRef<Region | null>(null);
  const [dragHandle, setDragHandle] = useState<null | DragHandleType>(null);
  const totalMs = useMemo(() => timeline[1] - timeline[0], [timeline]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (
        !dragHandle ||
        !selectedRegion ||
        !setSelectedRegion ||
        !innerContentRef.current
      )
        return;

      const rect = innerContentRef.current.getBoundingClientRect();
      const containerWidth = rect.width;
      const visibleMs = totalMs / scale;
      const msPerPixel = visibleMs / containerWidth;
      const deltaPx = e.clientX - startXRef.current;
      const deltaMs = deltaPx * msPerPixel;

      const handlePx = 2;
      const minDuration = 1000;
      const safeMinMs = Math.max(msPerPixel * handlePx, minDuration);

      const min = timeline[0];
      const max = timeline[1];

      if (dragHandle === DRAG_HANDLE.LEFT && initialRegionRef.current) {
        let newStart = initialRegionRef.current.start + deltaMs;
        newStart = Math.max(
          min,
          Math.min(newStart, initialRegionRef.current.end - safeMinMs),
        );
        setSelectedRegion({
          start: newStart,
          end: initialRegionRef.current.end,
        });
      }

      if (dragHandle === DRAG_HANDLE.RIGHT && initialRegionRef.current) {
        let newEnd = initialRegionRef.current.end + deltaMs;
        newEnd = Math.min(
          max,
          Math.max(newEnd, initialRegionRef.current.start + safeMinMs),
        );
        setSelectedRegion({
          start: initialRegionRef.current.start,
          end: newEnd,
        });
      }

      if (dragHandle === DRAG_HANDLE.REGION && initialRegionRef.current) {
        const duration =
          initialRegionRef.current.end - initialRegionRef.current.start;
        let newStart = initialRegionRef.current.start + deltaMs;
        let newEnd = newStart + duration;

        if (newStart < min) {
          newStart = min;
          newEnd = newStart + duration;
        }
        if (newEnd > max) {
          newEnd = max;
          newStart = newEnd - duration;
        }

        setSelectedRegion({ start: newStart, end: newEnd });
      }
    };

    const handleMouseUp = () => setDragHandle(null);

    if (dragHandle) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragHandle, selectedRegion, setSelectedRegion, scale, timeline, totalMs]);

  return (
    <div
      ref={(el) => {
        innerContentRef.current = el;
        contentRef(el); // forward to parent map
      }}
      className={`relative flex-1 bg-zinc-200 overflow-hidden ${
        isDraggable ? "cursor-grab" : ""
      }`}
    >
      <div
        className='absolute top-0 left-0 right-0 bottom-0'
        style={{ transform: `translateX(${offset}px)` }}
      >
        {/* Selected Region */}
        {selectedRegion && (
          <div
            className={`absolute top-[-8px] h-[calc(100%+16px)] rounded select-none ${
              displayOnly ? "z-20" : "z-30"
            }`}
            style={{
              left: `${
                ((selectedRegion.start - timeline[0]) / totalMs) * 100 * scale
              }%`,
              width: `${
                ((selectedRegion.end - selectedRegion.start) / totalMs) *
                100 *
                scale
              }%`,
            }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <div
              className={`absolute left-0 top-0 h-full w-2 bg-[#FF09D8] rounded-l ${
                displayOnly ? "" : "cursor-ew-resize"
              }`}
              onMouseDown={(e) => {
                if (displayOnly) return;
                e.stopPropagation();
                startXRef.current = e.clientX;
                initialRegionRef.current = selectedRegion;
                setDragHandle(DRAG_HANDLE.LEFT);
              }}
            />
            <div
              className={`absolute right-0 top-0 h-full w-2 bg-[#FF09D8] rounded-r ${
                displayOnly ? "" : "cursor-ew-resize"
              }`}
              onMouseDown={(e) => {
                if (displayOnly) return;
                e.stopPropagation();
                startXRef.current = e.clientX;
                initialRegionRef.current = selectedRegion;
                setDragHandle(DRAG_HANDLE.RIGHT);
              }}
            />
            <div
              className={`absolute inset-y-0 left-2 right-2 bg-[#F7D3FD] ${
                displayOnly ? "" : "cursor-grab opacity-50"
              }`}
              onMouseDown={(e) => {
                if (displayOnly) return;
                e.stopPropagation();
                startXRef.current = e.clientX;
                initialRegionRef.current = selectedRegion;
                setDragHandle(DRAG_HANDLE.REGION);
              }}
            />
          </div>
        )}

        {/* Current Position Bar */}
        {!!currentPosition && (
          <div
            className='absolute h-full bg-primary-blue-100'
            style={{
              left: 0,
              width: `${
                ((currentPosition - timeline[0]) / totalMs) * 100 * scale
              }%`,
            }}
          >
            <div className='absolute h-full right-0 border-r border-red-700 border-dashed z-40' />
          </div>
        )}

        {hoverTime && (
          <div
            className='absolute top-0 z-20 h-full border-x border-red-600 border-dashed'
            style={{
              left: `${((hoverTime - timeline[0]) / totalMs) * 100 * scale}%`,
            }}
          />
        )}

        {currentSection && (
          <Tooltip
            title={
              <div className='flex text-xs items-center gap-2'>
                <AiOutlineInfoCircle size={16} /> Logs
              </div>
            }
          >
            <div
              className='absolute top-0 z-10 h-full border border-black'
              style={{
                left: `${
                  ((currentSection[0] - timeline[0]) / totalMs) * 100 * scale
                }%`,
                width: `${
                  ((currentSection[1] - currentSection[0]) / totalMs) *
                  100 *
                  scale
                }%`,
              }}
            />
          </Tooltip>
        )}

        {/* Recordings + Interaction Bars */}
        {recordings.map((recording, index) => {
          const { id, startTimestamp, durationMs, userInteractions } =
            recording;

          const startOffset =
            startTimestamp < timeline[0]
              ? 0
              : ((startTimestamp - timeline[0]) / totalMs) * 100;

          const width =
            ((startTimestamp < timeline[0]
              ? durationMs - (timeline[0] - startTimestamp)
              : durationMs) /
              totalMs) *
            100;

          const allTimestamps: number[] = [
            ...(userInteractions.clicks || []).map(({ timestamp }) =>
              parseInt(timestamp || "", 10),
            ),
            ...(userInteractions.pageNavigations || []).map(({ timestamp }) =>
              parseInt(timestamp || "", 10),
            ),
            ...(userInteractions.textEdits || []).map(({ timestamp }) =>
              parseInt(timestamp || "", 10),
            ),
          ]
            .filter(Boolean)
            .sort((a, b) => a - b);

          const interactionRanges: { start: number; end: number }[] = [];
          let i = 0;
          while (i < allTimestamps.length) {
            const start = allTimestamps[i];
            while (
              i < allTimestamps.length &&
              allTimestamps[i] - start <= 3600 * 1000
            ) {
              i++;
            }
            interactionRanges.push({ start, end: allTimestamps[i] });
          }

          return (
            <div
              key={id}
              ref={(el) => (recordingsRef.current[index] = el)}
              className={`absolute h-full bg-blue-400 ${
                displayOnly && selectedRegion ? "z-30 opacity-50" : "z-20"
              }`}
              style={{
                left: `${startOffset * scale}%`,
                width: `${width * scale}%`,
                minWidth: 1,
              }}
              {...(onTimelineClick && {
                onClick: (e) => {
                  if (isMoving) return;
                  const rect =
                    recordingsRef.current[index]?.getBoundingClientRect();
                  if (rect) {
                    const multiplier =
                      (e.clientX - Math.floor(rect.left)) /
                      Math.floor(rect.width);
                    onTimelineClick(recording, durationMs * multiplier);
                  }
                },
                role: "button",
                tabIndex: 0,
              })}
            >
              {interactionRanges.map(({ start, end }) => {
                const left = ((start - timeline[0]) / totalMs) * 100;
                const rangeWidth = ((end - start) / totalMs) * 100;
                return (
                  <div
                    key={start}
                    className='absolute h-full bg-green-500'
                    style={{
                      left: `${left * scale}%`,
                      width: `${rangeWidth * scale}%`,
                      minWidth: 1,
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecordingsTimeline;
