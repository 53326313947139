import React from "react";
import { AiFillThunderbolt } from "react-icons/ai";
import { BiPlay, BiStop } from "react-icons/bi";
import { CgPlayBackwards, CgPlayForwards } from "react-icons/cg";
import IconButton from "./IconButton";

interface VideoPlayerControlsProps {
  playbackSpeed: number;
  disabled: boolean;
  displayOnly: boolean;
  backwardRecordingDisabled: boolean;
  forwardRecordingDisabled: boolean;
  skipInactivity: boolean;
  isPlaying: boolean;
  changeSpeed: (speed: number) => void;
  handleBackwardRecording: () => void;
  handleForwardRecording: () => void;
  togglePlayPause: () => void;
  toggleSkipInactivity: () => void;
}

const VideoPlayerControls: React.FC<VideoPlayerControlsProps> = ({
  playbackSpeed,
  disabled,
  displayOnly,
  backwardRecordingDisabled,
  forwardRecordingDisabled,
  skipInactivity,
  isPlaying,
  changeSpeed,
  handleBackwardRecording,
  handleForwardRecording,
  togglePlayPause,
  toggleSkipInactivity,
}) => {
  const withConfirmation = (cb: () => void) => () => {
    if (
      displayOnly ||
      confirm(
        "Are you sure you want to change the recording? You have unsaved changes.",
      )
    ) {
      cb();
    }
  };

  return (
    <div className='relative'>
      <div className='flex items-center w-full'>
        <div className='absolute left-1/2 transform -translate-x-1/2 flex gap-2'>
          <IconButton
            onClick={withConfirmation(handleBackwardRecording)}
            size='text-base'
            icon={CgPlayBackwards}
            disabled={disabled || backwardRecordingDisabled}
          />
          <IconButton
            onClick={togglePlayPause}
            size='text-base'
            flag={isPlaying}
            icon={BiPlay}
            trueIcon={BiStop}
            falseIcon={BiPlay}
            disabled={disabled}
          />
          <IconButton
            onClick={withConfirmation(handleForwardRecording)}
            size='text-base'
            icon={CgPlayForwards}
            disabled={disabled || forwardRecordingDisabled}
          />
        </div>
        <div className='ml-auto flex justify-end gap-2'>
          <select
            value={playbackSpeed}
            onChange={(e) => changeSpeed(Number(e.target.value))}
            className={`btn btn-primary bg-inherit ${
              disabled ? "text-gray-400" : "text-black"
            }`}
            disabled={disabled}
          >
            <option value={1}>1x</option>
            <option value={2}>2x</option>
            <option value={4}>4x</option>
          </select>
          <IconButton
            onClick={toggleSkipInactivity}
            size='text-base'
            flag={skipInactivity}
            icon={AiFillThunderbolt}
            disabled={disabled}
            tooltip={`${skipInactivity ? "Show" : "Hide"} Inactivity`}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerControls;
