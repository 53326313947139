import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import Checkbox from "../components/Checkbox";
import EngineeringTicketLink from "../components/EngineeringTicketLink";
import Table, { Order } from "../components/Table";
import { useCompany } from "../contexts/CompanyContext";
import {
  EngineeringTicketType,
  useGetFieldValuesQuery,
  useSearchUsingQueryQuery,
} from "../__generated__/graphql";
import formatRelativeTime from "../utils/dateFns";
import SearchQuery from "../utils/SearchQuery";

const DEFAULT_ITEMS_PER_PAGE = 25;
const SYSTEM = "SailfishAI System";
const COLUMNS = [
  { id: "id", label: "Issue ID" },
  {
    id: "createdBy",
    label: "Created",
    format: (value: string) =>
      value === SYSTEM ? "Automatically" : "Manually",
  },
  {
    id: "engineeringTicket",
    label: "Engineering Ticket",
    format: (ticket: EngineeringTicketType) => (
      <EngineeringTicketLink ticket={ticket} />
    ),
  },
  {
    id: "isResolved",
    label: "Is Resolved",
    format: (value: boolean) => (value ? "Yes" : "No"),
  },
  {
    id: "snoozedMode",
    label: "Snoozed Mode",
  },
  {
    id: "lastSeen",
    label: "Last Seen",
    order: true,
    format: (value: string) => formatRelativeTime(value),
  },
  {
    id: "createdAt",
    label: "Created At",
    order: true,
    format: (value: string) => formatRelativeTime(value),
  },
];

interface Filters {
  creator: string | null;
  hasEngineeringTicket: string | null;
  showResolved: boolean;
  showSnoozed: boolean;
}

const getSearchQuery = ({
  creator,
  hasEngineeringTicket,
  showResolved,
  showSnoozed,
}: Filters) => {
  const searchQuery = new SearchQuery();

  if (creator) {
    searchQuery.addSearchParameter("created_by_user__email", "==", creator);
  }
  if (hasEngineeringTicket) {
    searchQuery.addSearchParameter(
      "engineering_ticket__id",
      hasEngineeringTicket === "Yes" ? "!=" : "==",
      "Null",
    );
  }
  if (!showResolved) {
    searchQuery.addSearchParameter("is_resolved", "==", "False");
  }
  if (!showSnoozed) {
    searchQuery.addSearchParameter("snoozed_mode", "==", "Disabled");
  }

  return searchQuery.toString();
};

const IssuesPage = () => {
  const { selectedCompany } = useCompany();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [results, setResults] = useState<any[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [filters, setFilters] = useState<Filters>({
    creator: null,
    hasEngineeringTicket: null,
    showResolved: false,
    showSnoozed: false,
  });
  const [order, setOrder] = useState<Order>(
    () => (localStorage.getItem("issuesOrder") as Order) || "asc",
  );
  const [orderBy, setOrderBy] = useState(
    () => localStorage.getItem("issuesOrderBy") || "lastSeen",
  );
  const navigate = useNavigate();

  const { data } = useGetFieldValuesQuery({
    variables: {
      searchEntity: "Issues",
      fieldName: "created_by_user__email",
      ...(selectedCompany && {
        companyId: parseInt(selectedCompany.value, 10),
      }),
    },
  });

  const creators = data?.getFieldValues || [];

  const { loading } = useSearchUsingQueryQuery({
    variables: {
      searchEntity: "issues",
      searchQuery: getSearchQuery(filters),
      limit: itemsPerPage,
      offset: page * itemsPerPage,
      companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
      orderBy: `${order === "asc" ? "-" : ""}${
        orderBy === "lastSeen" ? "last_seen" : "created_at"
      }`,
    },
    onCompleted: (data) => {
      const results = data?.searchUsingQuery?.results.items || [];
      const totalItemsCount =
        data?.searchUsingQuery?.results.totalItemsCount || 0;
      setResults(results);
      setTotalItemsCount(totalItemsCount);
    },
  });

  const handleRowClick = (id: string) => {
    navigate(`/issues/${id}`);
  };

  const handleChangeOrder = (property: string) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    localStorage.setItem("issuesOrder", newOrder);
    localStorage.setItem("issuesOrderBy", property);
  };

  return (
    <ApplicationPage pageName='Issues'>
      <div className='h-[calc(100%-44px)]'>
        <div className='flex gap-4 pb-4'>
          <Autocomplete
            className='flex-1 min-w-80'
            options={creators}
            value={filters.creator}
            onChange={(_, value) =>
              setFilters((prev) => ({ ...prev, creator: value }))
            }
            renderInput={(params) => (
              <TextField {...params} label='Creator' variant='outlined' />
            )}
            size='small'
          />
          <Autocomplete
            className='flex-1'
            options={["Yes", "No"]}
            value={filters.hasEngineeringTicket}
            onChange={(_, value) =>
              setFilters((prev) => ({ ...prev, hasEngineeringTicket: value }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label='Has Engineering Ticket'
                variant='outlined'
              />
            )}
            size='small'
          />
          <div className='flex gap-1 items-center font-medium'>
            <Checkbox
              label='Show Snoozed'
              checked={filters.showSnoozed}
              onChange={() =>
                setFilters((prev) => ({
                  ...prev,
                  showSnoozed: !prev.showSnoozed,
                }))
              }
              disabled={false}
              tooltip=''
            />
          </div>
          <div className='flex gap-1 items-center font-medium'>
            <Checkbox
              label='Show Resolved'
              checked={filters.showResolved}
              onChange={() =>
                setFilters((prev) => ({
                  ...prev,
                  showResolved: !prev.showResolved,
                }))
              }
              disabled={false}
              tooltip=''
            />
          </div>
        </div>
        <div className='h-[calc(100%-72px)]'>
          <Table
            columns={COLUMNS}
            count={totalItemsCount}
            idKey='id'
            loading={loading}
            order={order}
            orderBy={orderBy}
            page={page}
            rows={results}
            rowsPerPage={itemsPerPage}
            handleChangeOrder={handleChangeOrder}
            handleChangeRowsPerPage={setItemsPerPage}
            onClick={handleRowClick}
            setPage={setPage}
          />
        </div>
      </div>
    </ApplicationPage>
  );
};

export default IssuesPage;
