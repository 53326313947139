import { useMutation } from "@apollo/client";
import rrwebPlayer from "@sailfish-rrweb/rrweb-player";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import "rrweb-player/dist/style.css";
import {
  RecordingSessionQueryResultsGroupedType,
  RecordingSessionType,
} from "../__generated__/graphql";
import { useCompany } from "../contexts/CompanyContext";
import { useWebPlayer } from "../contexts/WebPlayerContext";
import { CREATE_TRIAGE_MUTATION } from "../graphql/triage";
import {
  DeviceType,
  HighlightedSection,
  TimelineEvent,
} from "../utils/interfaces";
import GroupedRecordingsTimeline from "./GroupedRecordingsTimeline";
import LoadingAnimation from "./LoadingAnimation";
import { Region } from "./RecordingsTimeline";
import VideoPlayerControls from "./VideoPlayerControls";
import VideoPlayerTriage from "./VideoPlayerTriage";

interface RRWebPlayerProps {
  id?: string;
  events: any[];
  timelineEvents: TimelineEvent[];
  highlightedSections: HighlightedSection[];
  width: number;
  height: number;
  initialTime?: number | null;
  onTimeUpdate?: (currentTime: number) => void;
  containerWidth: number;
  eventsError: boolean;
  loadingEvents: boolean;
  handleBackwardRecording: () => void;
  handleForwardRecording: () => void;
  backwardRecordingDisabled: boolean;
  forwardRecordingDisabled: boolean;
  hideTriageButton?: boolean;
  selectedRecordings: RecordingSessionQueryResultsGroupedType | null;
  timeline: [number, number];
  handleSelectRecording: (recording: RecordingSessionType) => void;
  selectedDevice: DeviceType | null;
  selectedDeviceRecordings: RecordingSessionType[] | null;
  onPlay: (device: DeviceType, recordings: RecordingSessionType[]) => void;
  selectedRecording: RecordingSessionType;
}

type InactivePeriods = [number, number][];

const EMPTY_INACTIVE_PERIODS: InactivePeriods = [];

//
//
// TODO - add the events to the timeline...
//
//

const RRWebPlayer = forwardRef<any, RRWebPlayerProps>(
  (
    {
      id,
      events,
      width,
      height,
      initialTime,
      timelineEvents,
      highlightedSections,
      containerWidth,
      eventsError,
      loadingEvents,
      handleBackwardRecording,
      handleForwardRecording,
      backwardRecordingDisabled,
      forwardRecordingDisabled,
      hideTriageButton,
      selectedRecordings,
      timeline,
      handleSelectRecording,
      selectedDevice,
      selectedDeviceRecordings,
      onPlay,
      selectedRecording,
    },
    ref,
  ) => {
    const playerRef = useRef<any>(null);
    const playerContainerRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [autoPlay, setAutoPlay] = useState(true);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [skipInactivity, setSkipInactivity] = useState(true);
    const [displayOnly, setDisplayOnly] = useState(true); // State to toggle displayOnly
    const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    const [goToTime, setGoToTime] = useState<number | undefined>(undefined);
    const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
    const navigate = useNavigate();
    const { hoverTime, currentSection } = useWebPlayer();

    const [createTriage] = useMutation(CREATE_TRIAGE_MUTATION);
    const handleCreateTriage = () => {
      if (!selectedRegion) {
        alert(
          "Please select at least one time window before creating a triage.",
        );
        return;
      }

      if (!id) {
        alert("Recording id isn't provided.");
        return;
      }

      const triageInfoRecordings = selectedRecordings?.recordings?.reduce<
        any[]
      >((result, { id, startTimestamp, durationMs }) => {
        const endTimestamp = startTimestamp + durationMs;

        // Check if the recording is within or partially within the period
        if (
          endTimestamp >= selectedRegion.start &&
          startTimestamp <= selectedRegion.end
        ) {
          result.push({
            recordingId: id,
            identifiedProblemWindows: [
              {
                timestampStart: Math.round(
                  Math.max(startTimestamp, selectedRegion.start) -
                    startTimestamp,
                ),
                timestampEnd: Math.round(
                  Math.min(endTimestamp, selectedRegion.end) - startTimestamp,
                ),
              },
            ],
          });
        }

        return result;
      }, []);

      if (!triageInfoRecordings?.length) {
        alert(
          "Please select at least one time window before creating a triage.",
        );
        return;
      }

      createTriage({
        variables: {
          triageInfoRecordings,
          triageInfoLogs: null, // Assuming only recordings are handled here, update if necessary
          companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
        },
      })
        .then((response) => {
          if (response.data?.createTriage) {
            navigate(`/triage/${response.data.createTriage.id}`, {
              state: { from: "RRWebPlayerWithTriage" },
            });
          }
        })
        .catch((err) => {
          console.error("Error creating triage:", err);
        });
    };

    const onRecordingEnd = () => {
      if (!selectedDeviceRecordings) return;
      const playingRecordingIndex = selectedDeviceRecordings.findIndex(
        ({ id }) => id === selectedRecording?.id,
      );
      const playingRecordingLength = selectedDeviceRecordings.length;
      if (playingRecordingIndex < playingRecordingLength - 1) {
        handleSelectRecording(
          selectedDeviceRecordings[playingRecordingIndex + 1],
        );
      }
    };

    useImperativeHandle(ref, () => ({
      play() {
        playerRef.current.play();
        setIsPlaying(true);
      },
      pause() {
        playerRef.current.pause();
        setIsPlaying(false);
      },
      seekToPercentage(time: number) {
        playerRef.current.goto(time);
        setCurrentTime(time);
      },
      seekToTime(time: number) {
        playerRef.current.goto(time - startTime);
        setCurrentTime(time - startTime);
      },
      resetPlayer() {
        playerRef.current.goto(0);
        playerRef.current.play();
        setCurrentTime(0);
        setIsPlaying(true);
      },
    }));

    useEffect(() => {
      if (playerRef.current) {
        const replayer = playerRef.current.getReplayer();
        replayer.destroy();
        playerRef.current = null;
        const target = document.getElementById("rrweb-player");
        const child = target?.childNodes?.item(0);
        if (target && child) target.removeChild(child);
      }
    }, [id]);

    useEffect(() => {
      if (highlightedSections.length) {
        setSelectedRegion({
          start: highlightedSections[0].startTime,
          end: highlightedSections[highlightedSections.length - 1].endTime,
        });
      }
    }, [highlightedSections]);

    useEffect(() => {
      if (!id || !events) return;

      if (playerRef.current) {
        playerRef.current.pause();
      }

      const target = document.getElementById("rrweb-player");
      if (!playerRef.current && target) {
        playerRef.current = new rrwebPlayer({
          target,
          props: {
            events,
            autoPlay,
            showController: false,
            width: width,
            height: height,
            inactivePeriodThreshold: 10 * 1000,
            skipInactive: skipInactivity,
          },
        });

        const playerMetadata = playerRef.current.getMetaData();
        setStartTime(playerMetadata.startTime);
        setEndTime(playerMetadata.endTime);
        setTotalTime(playerMetadata.totalTime);
        setIsPlaying(autoPlay);

        if (goToTime !== undefined) {
          const time =
            goToTime > playerMetadata.totalTime
              ? playerMetadata.totalTime
              : goToTime;
          playerRef.current.goto(time);
          // Blur from the Player iframe so window keyboard listeners work as expected
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
          setCurrentTime(time);
          setGoToTime(undefined);
        } else {
          setCurrentTime(0);
        }

        playerRef.current.addEventListener(
          "ui-update-current-time",
          (event: any) => {
            setCurrentTime(event.payload);
          },
        );

        playerRef.current.addEventListener(
          "ui-update-player-state",
          (event: any) => {
            if (event.payload === "paused") {
              setIsPlaying(false);
            }
          },
        );
        playerRef.current.addEventListener("finish", () => {
          onRecordingEnd();
          setCurrentTime(0);
          setAutoPlay(true);
        });
      } else {
        playerRef.current.$set({
          events,
          width,
          height,
        });
        playerRef.current.triggerResize();

        if (initialTime) {
          playerRef.current.goto(initialTime);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, events, width, height, initialTime]);

    useEffect(() => {
      if (goToTime !== undefined && playerRef.current) {
        // Call the goto function asynchronously so the UI doesn't hang and we can show a loading animation
        setTimeout(() => {
          playerRef.current.goto(goToTime);
          // Blur from the Player iframe so window keyboard listeners work as expected
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
          setGoToTime(undefined);
          // There is no need to call setCurrentTime function, currentTime will be automatically changed from the player
        });
      }
    }, [goToTime]);

    const togglePlayPause = () => {
      if (!isPlaying) {
        playerRef.current.goto(currentTime);
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    };

    const changeSpeed = (speed: number) => {
      setPlaybackSpeed(speed);
      playerRef.current.setSpeed(speed);
    };

    const toggleSkipInactivity = () => {
      playerRef.current.toggleSkipInactive();
      setSkipInactivity((prev) => !prev);
    };

    const toggleDisplayMode = () => {
      if (selectedRegion) {
        setSelectedRegion(null);
      }
      setDisplayOnly((prev) => !prev);
    };

    const inactiveTimes = (playerRef.current?.inactivePeriods() ||
      EMPTY_INACTIVE_PERIODS) as InactivePeriods;

    const timelineEventsMapped = timelineEvents.map((event) => ({
      time: event.time - startTime,
      // time: ((event.time - startTime) / totalTime) * 100,
      // time: event.time,
      icon: <event.Icon className='text-primary-blue-100' />,
      color: event.color !== undefined ? event.color : "blue", // Assuming a default color, adjust as needed
      text: event.text, // Adjust if you need a different text
    }));

    const disabled = !id || !events;

    return (
      <div
        id='rrweb-tile'
        ref={playerContainerRef}
        className='w-full h-full flex flex-col'
      >
        <div className='shadow-md border border-gray-200 rounded-lg bg-white h-[470px] flex flex-col py-1 px-2 justify-center relative [&:hover_.invisible]:visible'>
          {loadingEvents ? (
            <LoadingAnimation text='Loading...' />
          ) : (
            <>
              <div key={id} id='rrweb-player' className='flex justify-center' />
              <div className='absolute w-[calc(100%_-_16px)] bottom-4 px-4 invisible'>
                <VideoPlayerControls
                  playbackSpeed={playbackSpeed}
                  disabled={disabled}
                  displayOnly={displayOnly}
                  backwardRecordingDisabled={backwardRecordingDisabled}
                  forwardRecordingDisabled={forwardRecordingDisabled}
                  skipInactivity={skipInactivity}
                  isPlaying={isPlaying}
                  changeSpeed={changeSpeed}
                  handleBackwardRecording={handleBackwardRecording}
                  handleForwardRecording={handleForwardRecording}
                  togglePlayPause={togglePlayPause}
                  toggleSkipInactivity={toggleSkipInactivity}
                />
              </div>
              {!!goToTime && (
                // Display loading animation on top of the player while seeking to time
                <div className='rounded-lg absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center z-10'>
                  <LoadingAnimation text='Loading...' />
                </div>
              )}
            </>
          )}
        </div>
        <div id='rrweb-playback-bar' className='relative mt-8'>
          <GroupedRecordingsTimeline
            currentPosition={
              selectedRecording.startTimestamp + (goToTime || currentTime)
            }
            currentSection={currentSection}
            displayOnly={displayOnly}
            enhanced
            hoverTime={hoverTime}
            recordings={selectedRecordings?.recordings || []}
            selected={selectedDevice}
            selectedRegion={selectedRegion}
            timeline={timeline}
            onPlay={onPlay}
            onTimelineClick={(recording, time) => {
              if (recording.id !== id) {
                handleSelectRecording(recording);
                setAutoPlay(isPlaying);
              }
              setGoToTime(time);
            }}
            setSelectedRegion={setSelectedRegion}
          />
          {!hideTriageButton && (
            <VideoPlayerTriage
              disabled={disabled}
              displayOnly={displayOnly}
              handleCreateTriage={handleCreateTriage}
              toggleDisplayMode={toggleDisplayMode}
            />
          )}
        </div>
      </div>
    );
  },
);

export default RRWebPlayer;
