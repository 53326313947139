import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiPlay } from "react-icons/bi";
import {
  FaCaretDown,
  FaCaretRight,
  FaExclamationTriangle,
} from "react-icons/fa";
import { useWebPlayer } from "../../contexts/WebPlayerContext";
import { UnifiedLog } from "../../utils/interfaces";

interface LogProps {
  log: UnifiedLog;
  depth?: number;
  seekToTime: (timeStamp: string) => void;
}

const getIcon = (level: string) => {
  if (level === "warn") {
    return <FaExclamationTriangle size={12} className='text-yellow-400' />;
  }
  if (["error", "exception"].includes(level)) {
    return <AiFillCloseCircle size={12} className='text-red-500' />;
  }
  return null;
};

const Log: React.FC<LogProps> = ({ log, depth = 0, seekToTime }) => {
  const { setHoverTime } = useWebPlayer();

  const { timestamp, formattedTimestamp, payload, level, children } = log;
  const [open, setOpen] = useState(false);

  const icon = getIcon(level);

  return (
    <>
      <Tooltip
        title={`Time(UTC): ${formattedTimestamp}`}
        placement='right-end'
        followCursor
      >
        <div
          className='relative flex py-0.5 px-1 cursor-pointer [&:hover_.invisible]:visible'
          onClick={(e) => {
            const selection = window.getSelection();
            if (selection && (selection.toString().length > 0 || e.shiftKey)) {
              return; // Do nothing if text is selected or Shift + Click is pressed
            }
            setOpen((open) => !open);
          }}
          role='button'
          tabIndex={0}
          onMouseEnter={() => setHoverTime(+timestamp)}
          onMouseLeave={() => setHoverTime(null)}
        >
          <div
            className='flex items-center w-2.5 min-w-2.5 bg-sky-200 my-[-2px] mr-2'
            style={{ marginRight: depth * 24 + 16 }}
          >
            <BiPlay
              size={24}
              className='invisible text-pink-500 mx-[-6px]'
              onClick={(e) => {
                e.stopPropagation();
                seekToTime(timestamp);
              }}
            />
          </div>
          <div
            className='absolute left-3.5 top-[11px] border-y border-green-500 border-dashed invisible'
            style={{ width: depth * 24 + (icon ? 14 : 28) }}
          />
          <div className='mt-1 min-w-3'>{icon}</div>
          <div className='mt-1 mr-1 min-w-3'>
            {open ? <FaCaretDown size={12} /> : <FaCaretRight size={12} />}
          </div>
          <div id='payload' className={open ? "" : "truncate"}>
            {payload}
          </div>
        </div>
      </Tooltip>
      {!!children?.length &&
        children.map((log, i) => (
          <Log key={i} log={log} depth={depth + 1} seekToTime={seekToTime} />
        ))}
    </>
  );
};

export default Log;
