import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaBug } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { Id, ToastContainer, toast } from "react-toastify";
import {
  RecordingSessionQueryResultsGroupedType,
  RecordingSessionType,
  useCreateIssueMutation,
  useGetEngineeringTicketPlatformIntegrationQuery,
  useGetTriageQuery,
} from "../__generated__/graphql";
import CopyToClipboardButton from "../components/Buttons/CopyToClipboardButton";
import CtaButton from "../components/Buttons/CtaButton";
import Checkbox from "../components/Checkbox";
import GroupedRecordingsTimeline from "../components/GroupedRecordingsTimeline";
import ApplicationPageWithCta from "../components/Layout/ApplicationPageWithCta";
import LoadingAnimation from "../components/LoadingAnimation";
import CtaModal from "../components/Modal/CtaModal";
import TriageLoadingScreen from "../components/Navigation/TriageLoadingScreen";
import RecordingSummary from "../components/RecordingSummary/RecordingSummary";
import RRWebPlayerWithTriage from "../components/RRWebPlayerWithTriage";
import { useCompany } from "../contexts/CompanyContext";
import { WebPlayerProvider } from "../contexts/WebPlayerContext";
import useContainerWidth from "../hooks/useContainerWidth";
import useUnzippedEvents from "../hooks/useUnzippedEvents";
import {
  DEVICE_TYPES,
  INTEGRATIONS,
  LINEAR_PRIORITIES,
} from "../utils/constants";
import {
  AutocompleteOption,
  DeviceType,
  HighlightedSection,
} from "../utils/interfaces";
import WebSocketService from "../utils/websocket";

const DEBUG = import.meta.env.VITE_DEBUG ? import.meta.env.VITE_DEBUG : false;

const PLAYER_HEIGHT_PX = 462;

const TriagePage = () => {
  const rrwebPlayerRef = useRef<any>(null);
  const toastIdRef = useRef<Id | null>(null);

  const { triageId } = useParams<{ triageId?: string }>();
  const [triageCreationTime, setTriageCreationTime] = useState<Date | null>(
    null,
  );
  const { selectedCompany } = useCompany();
  const [recordings, setRecordings] = useState<
    RecordingSessionQueryResultsGroupedType[]
  >([]);
  const [selectedRecording, setSelectedRecording] =
    useState<RecordingSessionType | null>(null);
  const [selectedRecordings, setSelectedRecordings] =
    useState<RecordingSessionQueryResultsGroupedType | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);
  const [selectedDeviceRecordings, setSelectedDeviceRecordings] = useState<
    RecordingSessionType[] | null
  >(null);
  const [timeline, setTimeline] = useState<[number, number]>([0, 0]);
  const [highlightedSections, setHighlightedSections] = useState<
    HighlightedSection[]
  >([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [issueName, setIssueName] = useState<string>("");
  const [issueDescription, setIssueDescription] = useState<string>("");
  const [issueTeam, setIssueTeam] = useState("");
  const [issueProject, setIssueProject] = useState("");
  const [issuePriority, setIssuePriority] = useState(0);
  const [errorMessageCreateIssue, setErrorMessageCreateIssue] = useState<
    string | null
  >(null);
  const [createEngineeringTicket, setCreateEngineeringTicket] = useState(true);

  const {
    unzippedEvents,
    timelineEvents,
    eventsError,
    loadingEvents,
    setEventsError,
    setUnzippedEvents,
    setTimelineEvents,
  } = useUnzippedEvents(selectedRecording?.id);
  const { containerRef, containerWidth } = useContainerWidth();

  const location = useLocation();
  const fromPage = location.state?.from || "unknown";

  const shouldShowTriageLoadingScreen = fromPage === "RRWebPlayerWithTriage";

  const handleLoadingComplete = useCallback(() => {
    setIsLoading(false);
  }, []);

  // Create Issue
  const [createIssueMutation] = useCreateIssueMutation();

  const { data: integrationData } =
    useGetEngineeringTicketPlatformIntegrationQuery({
      variables: {
        provider: INTEGRATIONS.LINEAR,
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
      onCompleted: (data) => {
        if (data?.getEngineeringTicketPlatformIntegration) {
          const { installed, defaultPriority, defaultProject, defaultTeam } =
            data.getEngineeringTicketPlatformIntegration;

          if (!installed) {
            return;
          }
          setIssueTeam(defaultTeam || "");
          setIssueProject(defaultProject || "");
          setIssuePriority(defaultPriority || 0);
        }
      },
    });

  const linearProjects: AutocompleteOption[] =
    integrationData?.getEngineeringTicketPlatformIntegration?.projects || [];
  const linearTeams: AutocompleteOption[] =
    integrationData?.getEngineeringTicketPlatformIntegration?.teams || [];
  const linearInstalled =
    integrationData?.getEngineeringTicketPlatformIntegration?.installed;

  // Fetch Triage data including creation time
  const {
    data: triageData,
    loading,
    error,
    refetch,
  } = useGetTriageQuery({
    variables: {
      triageId: triageId ? parseInt(triageId, 10) : 0,
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (DEBUG) console.log("Query completed. Data received:", data);

      if (data && data.getTriage) {
        setTriageCreationTime(new Date(data.getTriage.createdAt));

        const recordingData = data.getTriage.triageRecordingData;
        const logData = data.getTriage.triageLogData;
        const similarRecordings = data.getTriage.otherSimilarRecordings.map(
          (entry: any) => entry.recording,
        );

        const recordingsFromRecordingData = recordingData.map(
          (data: any) => data.recording,
        );
        const recordingsFromLogData = logData.map(
          (data: any) => data.recording,
        );

        const allRecordings: RecordingSessionQueryResultsGroupedType[] = [
          ...(recordingsFromRecordingData.length
            ? [
                {
                  userId: recordingsFromRecordingData[0].metadata.user,
                  recordings: recordingsFromRecordingData,
                },
              ]
            : []),
          ...recordingsFromLogData.map((recording) => ({
            userId: recording.metadata.user,
            recordings: [recording],
          })),
          ...similarRecordings.map((recording) => ({
            userId: recording.metadata.user,
            recordings: [recording],
          })),
        ];

        setRecordings(allRecordings);

        const firstGroup = allRecordings[0];
        const firstGroupRecordings = firstGroup.recordings;
        const firstRecording = firstGroupRecordings[0];
        const lastRecording =
          firstGroupRecordings[firstGroupRecordings.length - 1];
        setSelectedRecordings(firstGroup);
        setSelectedRecording(firstRecording);
        setSelectedDeviceRecordings(
          firstGroupRecordings.filter((r) => r.metadata?.device?.isDesktop),
        );

        setTimeline([
          firstRecording.startTimestamp,
          lastRecording.startTimestamp + lastRecording.durationMs,
        ]);

        setSelectedDevice(DEVICE_TYPES.DESKTOP);

        // Process timeWindows to create HighlightedSections
        const sections: HighlightedSection[] = recordingData.flatMap(
          (data: any) =>
            data.timeWindows.map((window: any) => ({
              startTime: data.recording.startTimestamp + window.timestampStart,
              endTime: data.recording.startTimestamp + window.timestampEnd,
              text: 'Marked as "Error" Segment',
              color: "#fcd34d",
            })),
        );

        setHighlightedSections(sections);
      } else {
        console.warn("Triage data not available or incomplete:", data);
      }
    },
    onError: (error) => {
      console.error("Error fetching triage:", error);
      setEventsError(true);
    },
  });

  // Notify user for status update
  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      const data = JSON.parse(message.data);
      if (
        data?.message === "statusUpdate" &&
        (!toastIdRef.current || !toast.isActive(toastIdRef.current))
      ) {
        toastIdRef.current = toast(
          <div className='flex gap-2 text-sm font-medium items-end'>
            <div className='flex-1'>
              Please Refresh the page to get the real Other Similar Recordings
            </div>
            <button
              className='h-8 px-2 shadow rounded-md border'
              onClick={() => refetch()}
            >
              Refresh
            </button>
          </div>,
          { type: "info", className: "w-96" },
        );
      }
    };

    WebSocketService.subscribe(handleMessage);

    return () => {
      WebSocketService.unsubscribe(handleMessage);
    };
  }, [refetch]);

  // Updated toast
  useEffect(() => {
    if (toastIdRef.current) {
      if (loading) {
        toast.update(toastIdRef.current, {
          render: (
            <div className='flex gap-2 text-sm font-medium items-center'>
              <div className='flex-1'>Refreshing data...</div>
              <div className='flex items-center gap-2'>
                <PuffLoader color='#4A90E2' size={24} />
              </div>
            </div>
          ),
          type: "info",
          autoClose: false,
        });
      } else {
        toast.update(toastIdRef.current, {
          render: (
            <div className='text-sm font-medium'>Data has been updated!</div>
          ),
          type: "success",
          autoClose: 3000,
        });
        toastIdRef.current = null;
      }
    }
  }, [loading, toastIdRef]);

  // Calculate time delta for loading animation
  const calculateAnimationOffset = () => {
    if (!triageCreationTime) return 0;
    const now = new Date();
    return Math.max(0, now.getTime() - triageCreationTime.getTime());
  };

  if (error || eventsError) return <div>Error loading data</div>;

  const handleSelectRecording = (recording: RecordingSessionType) => {
    if (selectedRecording?.id === recording.id) {
      // Same recording selected, seek back to the start and keep playing
      rrwebPlayerRef.current?.resetPlayer();
    } else {
      setUnzippedEvents(null); // Reset events to force RRWebPlayer re-initialization
      setTimelineEvents([]);
      setSelectedRecording(recording);
    }
  };

  const createIssue = async () => {
    if (DEBUG) console.log("createIssue");
    if (!issueName.trim() || !issueDescription.trim()) {
      setErrorMessageCreateIssue(
        "Both 'Issue Name' and 'Description' fields are required.",
      );
      return;
    }
    if (!triageId) {
      console.error("Unable to create issue - triageId is not set");
      return;
    }

    // Clear any existing error messages
    setErrorMessageCreateIssue(null);

    try {
      // Execute the createIssue mutation
      const { data } = await createIssueMutation({
        variables: {
          input: {
            title: issueName.trim(),
            description: issueDescription.trim(),
            triageIds: [triageId],
          },
          createEngineeringTicket,
          ...(createEngineeringTicket && {
            teamId: issueTeam,
            projectId: issueProject,
            priority: issuePriority,
          }),
          companyId: selectedCompany
            ? parseInt(selectedCompany.value, 10)
            : undefined,
        },
      });
      if (!data) {
        console.error("Unable to create issue");
        return;
      }

      if (data.createIssue) {
        // Navigate to the newly created issue page
        navigate(`/issues/${data.createIssue.id}`);
      } else {
        setErrorMessageCreateIssue(
          "Failed to create the issue. Please try again.",
        );
      }
    } catch (error) {
      console.error("Error creating issue:", error);
      setErrorMessageCreateIssue(
        "An error occurred while creating the issue. Please try again.",
      );
    }
  };

  const pageName = `Triages > Triage ${triageId}`;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRecordingGroupChange = (
    recordingGroup: RecordingSessionQueryResultsGroupedType,
  ) => {
    setSelectedRecordings(recordingGroup);
    const deviceRecordings = recordingGroup.recordings.filter(
      selectedDevice === "Mobile"
        ? (r) => r.metadata?.device?.isMobile
        : (r) => r.metadata?.device?.isDesktop,
    );
    setSelectedDeviceRecordings(deviceRecordings);
    handleSelectRecording(deviceRecordings[0]);
  };

  const currentRecordingIndex = recordings.findIndex(
    ({ userId }) => userId === selectedRecordings?.userId,
  );
  const isFirstRecording = currentRecordingIndex === 0;
  const isLastRecording = currentRecordingIndex === recordings.length - 1;

  const handleBackwardRecording = () => {
    handleRecordingGroupChange(recordings[currentRecordingIndex - 1]);
  };

  const handleForwardRecording = () => {
    handleRecordingGroupChange(recordings[currentRecordingIndex + 1]);
  };

  const backwardRecordingDisabled = isFirstRecording;
  const forwardRecordingDisabled = isLastRecording;

  const createTicketFields = [
    <Checkbox
      key='createEngineeringTicket'
      label='Create Engineering Ticket'
      checked={createEngineeringTicket}
      onChange={(checked) => setCreateEngineeringTicket(checked)}
      disabled={false}
      tooltip=''
    />,
    ...(createEngineeringTicket
      ? [
          <Autocomplete
            key='Team'
            options={linearTeams}
            getOptionLabel={(option) => option.name}
            value={linearTeams.find(({ id }) => id == issueTeam) || null}
            onChange={(event, value) => setIssueTeam(value?.id || "")}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label='Team' variant='outlined' />
            )}
          />,
          <Autocomplete
            key='Project'
            options={linearProjects}
            getOptionLabel={(option) => option.name}
            value={linearProjects.find(({ id }) => id == issueProject) || null}
            onChange={(event, value) => setIssueProject(value?.id || "")}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label='Project' variant='outlined' />
            )}
          />,
          <Autocomplete
            key='Priority'
            disableClearable
            options={LINEAR_PRIORITIES}
            getOptionLabel={(option) => option.name} // Maps `name` for display
            value={LINEAR_PRIORITIES.find(
              ({ value }) => value == issuePriority,
            )}
            onChange={(event, newValue) =>
              setIssuePriority(newValue?.value || 0)
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            } // Compares `value`
            renderInput={(params) => (
              <TextField {...params} label='Priority' variant='outlined' />
            )}
          />,
        ]
      : []),
  ];

  return (
    <ApplicationPageWithCta
      pageName={pageName}
      helpLink='https://docs.sailfishqa.com/'
      helpTooltip='Click here to learn about Triages.'
      CtaButton={
        <div className='flex gap-2'>
          <CopyToClipboardButton
            title='Share'
            startIcon={<FiShare size={16} />}
          />
          <CtaButton
            titlePrimary='Create Issue'
            icon={<FaBug />}
            bgPrimaryClass='bg-red-500'
            textPrimaryClass='text-white'
            bgPrimaryHoverClass='hover:bg-red-600'
            textPrimaryHoverClass='hover:text-white'
            onClick={handleOpenModal}
            showOutlineHoverPrimary={false}
            additionalClassNames='!px-2.5 h-8 rounded-md text-sm leading-6 font-medium shadow-md'
            additionalWidth='4px'
            startIcon
          />
        </div>
      }
    >
      {isModalOpen && (
        <CtaModal
          title='Create Issue'
          closeModal={handleCloseModal}
          fields={[
            {
              label: "Issue Name",
              type: "input",
              placeholderText: "Enter a name for the issue",
              onChange: (e) => setIssueName(e.target.value),
            },
            {
              label: "Description",
              type: "textarea",
              placeholderText: "Users are experiencing...",
              onChange: (e) => setIssueDescription(e.target.value),
            },
          ]}
          additionalFields={linearInstalled ? createTicketFields : []}
          buttons={[
            {
              button: (
                <CtaButton
                  titlePrimary='Cancel'
                  bgPrimaryClass='bg-gray-300'
                  textPrimaryClass='text-secondary'
                  bgPrimaryHoverClass='hover:bg-gray-500'
                  textPrimaryHoverClass='hover:text-primary-dark'
                  outlinePrimaryHoverClass='hover:outline hover:outline-2 hover:outline-offset-0 hover:outline-gray-800'
                  additionalClassNames='w-full'
                  onClick={handleCloseModal}
                />
              ),
              width: 50,
            },
            {
              button: (
                <CtaButton
                  titlePrimary='Save'
                  additionalClassNames='w-full'
                  fontBold={true}
                  onClick={createIssue}
                />
              ),
              width: 50,
            },
          ]}
        />
      )}
      {loading && !recordings.length ? (
        <LoadingAnimation text='Loading...' />
      ) : (
        <div className='relative flex flex-col gap-4 flex-1'>
          <WebPlayerProvider>
            <div ref={containerRef}>
              <div className='h-full'>
                {selectedRecording && (
                  <RRWebPlayerWithTriage
                    id={selectedRecording.id}
                    ref={rrwebPlayerRef}
                    events={unzippedEvents}
                    timelineEvents={timelineEvents}
                    highlightedSections={highlightedSections}
                    width={containerWidth - 16}
                    height={PLAYER_HEIGHT_PX}
                    containerWidth={containerWidth}
                    eventsError={eventsError}
                    loadingEvents={loadingEvents}
                    handleBackwardRecording={handleBackwardRecording}
                    handleForwardRecording={handleForwardRecording}
                    backwardRecordingDisabled={backwardRecordingDisabled}
                    forwardRecordingDisabled={forwardRecordingDisabled}
                    hideTriageButton
                    handleSelectRecording={handleSelectRecording}
                    selectedRecordings={selectedRecordings}
                    timeline={timeline}
                    selectedDevice={selectedDevice}
                    selectedDeviceRecordings={selectedDeviceRecordings}
                    onPlay={(device, deviceRecordings) => {
                      setSelectedDeviceRecordings(deviceRecordings);
                      handleSelectRecording(deviceRecordings[0]);
                      setSelectedDevice(device);
                    }}
                    selectedRecording={selectedRecording}
                  />
                )}
              </div>
            </div>
            <RecordingSummary
              recording={selectedRecording}
              seekToTime={(time) => {
                if (rrwebPlayerRef.current) {
                  rrwebPlayerRef.current.seekToTime(time);
                }
              }}
            />
          </WebPlayerProvider>
          <div className='flex flex-col gap-3 text-xs pb-4'>
            <div className='font-bold text-sm'>Similar Recordings</div>
            {recordings.map(({ userId, recordings }) => (
              <div
                key={userId}
                className='flex gap-2 border-b border-slate-300 pb-3'
              >
                <p className='w-28 truncate font-medium'>{userId}</p>
                <GroupedRecordingsTimeline
                  recordings={recordings}
                  selected={
                    selectedRecordings?.userId === userId && selectedDevice
                  }
                  timeline={timeline}
                  onPlay={(device, deviceRecordings) => {
                    setSelectedRecordings({ userId, recordings });
                    setSelectedDeviceRecordings(deviceRecordings);
                    handleSelectRecording(deviceRecordings[0]);
                    setSelectedDevice(device);
                  }}
                />
              </div>
            ))}
          </div>

          {isLoading && shouldShowTriageLoadingScreen && (
            <div className='absolute inset-0 z-10 bg-white bg-opacity-80 flex items-center justify-center'>
              <TriageLoadingScreen
                onComplete={handleLoadingComplete}
                animationOffset={calculateAnimationOffset()}
              />
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </ApplicationPageWithCta>
  );
};

export default TriagePage;
