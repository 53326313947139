import { gql } from "@apollo/client";

export const RECORDING_FRAGMENT = gql`
  fragment RecordingFragment on RecordingSessionType {
    backendLogs {
      formattedTimestamp
      level
      statement
      timestamp
    }
    consoleLogs {
      formattedTimestamp
      level
      payload
      timestamp
      trace
    }
    durationMs
    exceptions {
      formattedTimestamp
      exceptionMessage
      timestamp
      traceJson
    }
    startTimestamp
    id
    printStatements {
      formattedTimestamp
      output
      statement
      timestamp
    }
    userActionsCount
    userInteractions {
      clicks {
        timestamp
      }
      pageNavigations {
        timestamp
      }
      textEdits {
        timestamp
      }
    }
    metadata {
      user
      device {
        isBot
        isTelevision
        isMobile
        isDesktop
        device {
          brand
          model
          type
        }
        client {
          name
          type
          version
        }
        os {
          name
          version
        }
      }
      language
      timeZone
      region
    }
  }
`;

export const CREATE_TRIAGE_MUTATION = gql`
  mutation CreateTriage(
    $triageInfoRecordings: [TriageInputRecording!]
    $triageInfoLogs: [TriageInputLogs!]
    $companyId: Int
  ) {
    createTriage(
      triageInfoRecordings: $triageInfoRecordings
      triageInfoLogs: $triageInfoLogs
      companyId: $companyId
    ) {
      id
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
// Removed this from above since we only need to know it was created
// triageRecordingData {
//   timeWindows {
//     timestampStart
//     timestampEnd
//   }
// }

export const GET_TRIAGE = gql`
  query GetTriage($triageId: Int!, $companyId: Int) {
    getTriage(triageId: $triageId, companyId: $companyId) {
      affectedPercentageUsers
      change24h
      createdBy
      createdAt
      devices {
        isDesktop
        isMobile
        isBot
        isTelevision
        device {
          type
          brand
        }
      }
      lastSeen
      recordingSession {
        pk
      }
      severity
      triageRecordingData {
        recording {
          ...RecordingFragment
        }
        timeWindows {
          timestampStart
          timestampEnd
        }
      }
      triageLogData {
        recording {
          ...RecordingFragment
        }
      }
      otherSimilarRecordings {
        recording {
          ...RecordingFragment
        }
        score
      }
      updatedBy
    }
  }
`;
