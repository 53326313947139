import React from "react";
import { NavLink } from "react-router-dom";

interface IntegrationButtonProps {
  logo: string;
  name: string;
  to: string;
  onClick?: () => void;
  nameTextPreamble?: string;
}

const IntegrationButton: React.FC<IntegrationButtonProps> = ({
  logo,
  name,
  to,
  onClick,
  nameTextPreamble = "Integrate with",
}) => {
  if (onClick) {
    // If an onClick is provided, just render a button.
    return (
      <button
        className='flex items-center bg-white text-black px-4 py-2 shadow rounded-md gap-2 text-sm font-medium'
        onClick={onClick}
      >
        <img src={logo} width={24} alt={`${name} Logo`} />
        {nameTextPreamble} {name}
      </button>
    );
  }

  // Otherwise, wrap in a NavLink for navigation.
  return (
    <NavLink to={to} className='link' target='_blank'>
      <button className='flex items-center bg-white text-black px-4 py-2 shadow rounded-md gap-2 text-sm font-medium'>
        <img src={logo} width={24} alt={`${name} Logo`} />
        {nameTextPreamble} {name}
      </button>
    </NavLink>
  );
};

export default IntegrationButton;
