class SearchQuery {
  private query = "";

  private addCondition(condition: string) {
    if (condition) {
      this.query = this.query ? `${this.query} && ${condition}` : condition;
    }
  }

  addSearchParameter(field: string, comparator: string, value: string) {
    this.addCondition(`${field} ${comparator} ${value}`);
    return this;
  }

  toString() {
    return this.query;
  }
}

export default SearchQuery;
