import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Checkbox from "@mui/material/Checkbox";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import LoadingAnimation from "./LoadingAnimation";

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export type Order = "asc" | "desc";
type Row = Record<string, any>;

interface Column<T extends string> {
  id: T;
  label: string;
  minWidth?: number;
  align?: "right";
  order?: boolean;
  format?: (value: any, row: Row) => ReactNode;
}

interface TableProps<T extends string> {
  checkboxSelection?: boolean;
  columns: Array<Column<T>>;
  count: number;
  idKey: T;
  loading?: boolean;
  order?: Order;
  orderBy?: T;
  page: number;
  rows: Array<Row>;
  rowsPerPage: number;
  selected?: string[];
  handleChangeOrder?: (orderBy: T) => void;
  handleChangeRowsPerPage: (rowsPerPage: number) => void;
  onClick?: (id: string) => void;
  setPage: Dispatch<SetStateAction<number>>;
  setSelected?: Dispatch<SetStateAction<string[]>>;
}

const Table = <T extends string>({
  checkboxSelection,
  columns,
  count,
  idKey,
  loading,
  order,
  orderBy,
  page,
  rows,
  rowsPerPage,
  selected,
  handleChangeOrder,
  handleChangeRowsPerPage,
  onClick,
  setPage,
  setSelected,
}: TableProps<T>): ReactElement => {
  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") setIsShiftPressed(true);
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") setIsShiftPressed(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSelected) {
      setSelected(
        event.target.checked ? rows.map(({ [idKey]: id }) => id) : [],
      );
      setLastSelected(null);
    }
  };

  const handleClick = (event: React.MouseEvent, id: string) => {
    if (!setSelected) return;

    setSelected((prevSelected) => {
      const isSelected = prevSelected.includes(id);

      if (event.shiftKey && lastSelected !== null) {
        // Shift + Click: Select a range of rows
        const ids = rows.map((row) => row[idKey]);
        const start = ids.indexOf(lastSelected);
        const end = ids.indexOf(id);
        const range = ids.slice(Math.min(start, end), Math.max(start, end) + 1);
        const newSelection = Array.from(new Set([...prevSelected, ...range]));
        return newSelection;
      }

      if (event.metaKey || event.ctrlKey) {
        // Cmd/Ctrl + Click: Toggle selection of the clicked row
        return isSelected
          ? prevSelected.filter((s) => s !== id)
          : [...prevSelected, id];
      }

      // Normal Click: Select only the clicked row
      return [id];
    });

    setLastSelected(id);
  };

  const numSelected = selected?.length || 0;
  const rowCount = rows.length;

  return (
    <div className='flex flex-col w-full h-full overflow-hidden border rounded-md shadow'>
      <TableContainer className='h-[calc(100%-52px)] flex flex-col'>
        <MUITable stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {checkboxSelection && (
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='primary'
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sortDirection={orderBy === column.id ? order : false}
                  className='!font-bold'
                >
                  {column.order ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      {...(handleChangeOrder && {
                        onClick: () => handleChangeOrder(column.id),
                      })}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const id = row[idKey];
              const isItemSelected = selected?.includes(id);
              const props: Partial<TableRowProps> =
                onClick || checkboxSelection
                  ? {
                      className: "cursor-pointer",
                      onClick: (e) =>
                        onClick ? onClick(id) : handleClick(e, id),
                    }
                  : {};

              return (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={id}
                  {...props}
                >
                  {checkboxSelection && (
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": `table-checkbox-${id}`,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(e, id);
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={isShiftPressed ? "select-none" : ""}
                      >
                        {column.format ? column.format(value, row) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MUITable>
        {loading && !rowCount && <LoadingAnimation text='Loading...' />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_: any, newPage: number) => setPage(newPage)}
        onRowsPerPageChange={(e) => handleChangeRowsPerPage(+e.target.value)}
        classes={{ spacer: "hidden", toolbar: "!p-0 justify-center" }}
      />
    </div>
  );
};

export default Table;
