import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useDebounce } from "use-debounce";
import { RecordingSessionType } from "../../__generated__/graphql";
import { useWebPlayer } from "../../contexts/WebPlayerContext";
import { UnifiedLog } from "../../utils/interfaces";
import VirtualizedList from "../VirtualizedList";
import Log from "./Log";

const LOG_LEVELS = {
  Log: ["log", "info", "print", "UNKNOWN"],
  Warn: ["warn"],
  Error: ["error", "exception"],
};

type LogLevel = keyof typeof LOG_LEVELS;

interface LogProps {
  recording: RecordingSessionType | null;
  seekToTime: (timeStamp: string) => void;
}

const getFilterCondition = (search: string, level: LogLevel | null) => {
  const conditions: ((log: UnifiedLog) => boolean)[] = [];

  if (search) {
    conditions.push(({ payload }) =>
      payload.toLowerCase().includes(search.toLowerCase()),
    );
  }
  if (level) {
    conditions.push(({ level: logLevel }) =>
      LOG_LEVELS[level]?.includes(logLevel),
    );
  }

  if (conditions.length === 0) {
    return () => true; // No filters applied
  }

  return (log: UnifiedLog) => conditions.every((condition) => condition(log));
};

const Logs: React.FC<LogProps> = ({ recording, seekToTime }) => {
  const { setCurrentSection } = useWebPlayer();
  const parentRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [level, setLevel] = useState<LogLevel | null>(null);
  const [type, setType] = useState<string | null>(null);

  const combinedLogs: UnifiedLog[] = useMemo(() => {
    const consoleLogs = recording?.consoleLogs || [];
    const backendLogs = recording?.backendLogs || [];
    const printStatements = recording?.printStatements || [];
    const exceptions = recording?.exceptions || [];

    const allLogs: UnifiedLog[] = [
      ...consoleLogs.map((log) => ({
        timestamp: log.timestamp,
        formattedTimestamp: log.formattedTimestamp,
        payload: log.payload,
        level: log.level,
        trace: log.trace,
        logType: log.__typename,
        children: [],
      })),
      ...backendLogs.map((log) => ({
        timestamp: log.timestamp,
        formattedTimestamp: log.formattedTimestamp,
        payload: log.statement,
        level: log.level,
        trace: undefined,
        logType: log.__typename,
      })),
      ...printStatements.map((log) => ({
        timestamp: log.timestamp,
        formattedTimestamp: log.formattedTimestamp,
        payload: log.statement,
        level: log.output,
        trace: undefined,
        logType: log.__typename,
      })),
      ...exceptions.map((log) => ({
        timestamp: log.timestamp,
        formattedTimestamp: log.formattedTimestamp,
        payload: log.exceptionMessage,
        level: "exception",
        trace: JSON.parse(log.traceJson), // Parsing trace JSON into array
        logType: log.__typename,
      })),
    ];

    // Sort logs chronologically by timestamp
    allLogs.sort((a, b) => parseInt(a.timestamp) - parseInt(b.timestamp));

    const structuredLogs: UnifiedLog[] = [];
    let currentParent: UnifiedLog | null = null;

    allLogs.forEach((log) => {
      if (log.logType === "ConsoleLog") {
        // If it's a frontend log, it becomes a new parent
        structuredLogs.push(log);
        currentParent = log;
      } else {
        // If it's a backend log, attach it to the closest preceding ConsoleLog
        if (currentParent) {
          currentParent.children?.push(log);
        } else {
          // If there's no parent yet, treat it as top-level (fallback case)
          structuredLogs.push(log);
        }
      }
    });

    return structuredLogs;
  }, [recording]);

  const filteredLogs = useMemo(() => {
    if (!debouncedSearch && !level && !type) {
      return combinedLogs;
    }

    let logs = combinedLogs;
    if (type) {
      logs =
        type === "Frontend"
          ? combinedLogs.map((log) => ({ ...log, children: [] }))
          : combinedLogs.reduce<UnifiedLog[]>((acc, log) => {
              if (log.children?.length) {
                acc.push(...log.children);
              }
              return acc;
            }, []);
    }

    const condition = getFilterCondition(debouncedSearch, level);

    const filterRecursive = (logs: UnifiedLog[]): UnifiedLog[] => {
      return logs.reduce<UnifiedLog[]>((acc, log) => {
        // Recursively filter children
        const filteredChildren = log.children
          ? filterRecursive(log.children)
          : [];

        // If the log itself matches or has matching children, include it
        if (condition(log) || filteredChildren.length > 0) {
          acc.push({ ...log, children: filteredChildren });
        }

        return acc;
      }, []);
    };

    return filterRecursive(logs);
  }, [combinedLogs, level, debouncedSearch, type]);

  const virtualizedListItems = useMemo(() => {
    const addLog = (acc: UnifiedLog[], log: UnifiedLog, depth = 0) => {
      const { children, ...fields } = log;
      acc.push({ ...fields, depth });
      if (children?.length) {
        children.forEach((log) => addLog(acc, log, depth + 1));
      }
    };
    return filteredLogs.reduce<UnifiedLog[]>((acc, log) => {
      addLog(acc, log);
      return acc;
    }, []);
  }, [filteredLogs]);

  const logs = useMemo(
    () => (
      <VirtualizedList
        items={virtualizedListItems}
        parentRef={parentRef}
        textSelector='#payload'
        estimateSize={() => 24}
        getCopyText={(leftIndex, leftOffset, rightIndex, rightOffset) => {
          const copyItems = virtualizedListItems
            .slice(leftIndex, rightIndex + 1)
            .map(({ payload }) => payload);

          const { length } = copyItems;
          if (length === 1) {
            copyItems[0] = copyItems[0].slice(leftOffset, rightOffset);
          } else if (length > 1) {
            copyItems[0] = copyItems[0].slice(leftOffset, copyItems[0].length);
            copyItems[length - 1] = copyItems[length - 1].slice(0, rightOffset);
          }
          return copyItems.join("\n");
        }}
        onVisibleItemsChange={(start, end) =>
          setCurrentSection([
            +virtualizedListItems[start].timestamp,
            +virtualizedListItems[end].timestamp,
          ])
        }
        renderItem={(log: UnifiedLog) => (
          <Log log={log} seekToTime={seekToTime} depth={log.depth} />
        )}
      />
    ),
    [virtualizedListItems, seekToTime, setCurrentSection],
  );

  return (
    <div className='flex flex-col h-full'>
      <div className='flex gap-1 mb-1 ml-1'>
        <TextField
          size='small'
          variant='outlined'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='w-48'
          classes={{
            root: "[&>div>input]:text-xs [&>div>input]:py-0.5",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FaSearch size={12} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position='end'
                className={search ? "visible" : "invisible"}
              >
                <MdClose
                  size={12}
                  className='cursor-pointer'
                  onClick={() => setSearch("")}
                />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          size='small'
          className='w-20'
          options={Object.keys(LOG_LEVELS) as LogLevel[]}
          value={level}
          onChange={(_, value) => setLevel(value)}
          classes={{
            clearIndicator: "[&>svg]:text-xs !p-0",
            input: "!py-0.5",
            inputRoot: "!text-xs !py-0",
            popupIndicator: "[&>svg]:text-sm !p-0",
          }}
          renderInput={(params) => (
            <TextField {...params} variant='outlined' placeholder='Level' />
          )}
        />
        <Autocomplete
          size='small'
          className='w-28'
          options={["Frontend", "Backend"]}
          value={type}
          onChange={(_, value) => setType(value)}
          classes={{
            clearIndicator: "[&>svg]:text-xs !p-0",
            input: "!py-0.5",
            inputRoot: "!text-xs !py-0 !pr-8",
            popupIndicator: "[&>svg]:text-sm !p-0",
          }}
          renderInput={(params) => (
            <TextField {...params} variant='outlined' placeholder='Type' />
          )}
        />
      </div>
      <div
        ref={parentRef}
        className='flex-grow overflow-auto text-xs leading-5'
      >
        {logs}
      </div>
    </div>
  );
};

export default Logs;
