import { useEffect, useRef, useState } from "react";
import useContainerWidth from "../hooks/useContainerWidth";

interface FilterListProps<T extends string> {
  filters: Record<T, string[]>;
  filtersLabels?: Record<T, string>;
  onRemove: (key: T, value: string) => void;
}

const FilterList = <T extends string>({
  filters,
  filtersLabels,
  onRemove,
}: FilterListProps<T>) => {
  const { containerRef, containerWidth } = useContainerWidth();
  const [expanded, setExpanded] = useState(false);
  const [hiddenCount, setHiddenCount] = useState(0);
  const filterRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (!containerRef.current) return;

    let firstRowTop: number | null = null;
    let wrappedCount = 0;

    filterRefs.current.forEach((filter) => {
      if (!filter) return;
      const top = filter.offsetTop;

      if (firstRowTop === null) firstRowTop = top;
      if (top !== firstRowTop) wrappedCount++;
    });

    setHiddenCount(wrappedCount);
  }, [filters, containerWidth, expanded, containerRef]);

  const filterList = Object.entries(filters).flatMap(([key, values]) =>
    (values as string[]).map((value) => ({ key: key as T, value })),
  );

  const lastShownFilterIndex = filterList.length - 1 - hiddenCount;
  const lastShownFilterRef = filterRefs.current[lastShownFilterIndex];
  const remainingSpace = lastShownFilterRef
    ? containerWidth -
      (lastShownFilterRef.offsetWidth + lastShownFilterRef.offsetLeft)
    : 0;

  return (
    <div className='relative w-full text-sm'>
      <div className='flex flex-wrap gap-2'>
        <div
          ref={containerRef}
          className={`flex flex-wrap gap-2 transition-all ${
            expanded ? "overflow-x-hidden" : "overflow-hidden max-h-[32px]"
          } flex-1`}
        >
          {filterList.map(({ key, value }, index) => (
            <div
              key={`${key}:${value}`}
              ref={(el) => (filterRefs.current[index] = el)}
              className='bg-purple-200 px-3 py-1.5 rounded-full flex items-center max-w-full'
            >
              <span className='truncate'>
                {filtersLabels?.[key] || key}: {value}
              </span>
              <button
                className='ml-2 text-gray-500 hover:text-gray-700'
                onClick={() => onRemove(key, value)}
              >
                ✕
              </button>
            </div>
          ))}
          {expanded && (
            <button
              className='text-blue-500'
              onClick={() => setExpanded(false)}
            >
              Show less
            </button>
          )}
        </div>
        {!expanded && (
          <div className='relative w-16'>
            {hiddenCount > 0 && (
              <button
                className='absolute w-full bg-purple-200 px-3 py-1.5 rounded-full'
                style={{ left: -remainingSpace }}
                onClick={() => setExpanded(true)}
              >
                +{hiddenCount}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterList;
