import { gql, useLazyQuery } from "@apollo/client";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { useSearchUsingQueryLazyQuery } from "../../__generated__/graphql";
import { useCompany } from "../../contexts/CompanyContext";
import { RECORDINGS_EMPTY_FILTERS } from "../../utils/constants";
import { RecordingsFilters } from "../../utils/interfaces";
import SearchQuery from "../../utils/SearchQuery";
import Checkbox from "../Checkbox";

// Custom PopperComponent to ensure consistent dropdown positioning
const CustomPopper = (props: any) => {
  const { anchorEl, ...other } = props;

  return (
    <Popper
      {...other}
      anchorEl={anchorEl}
      style={{
        width: "auto", // Make width dynamic to fit content
        minWidth: anchorEl?.clientWidth || 0, // Set minimum width to anchor element width (input field)
        whiteSpace: "nowrap", // Prevent content from wrapping
      }}
      placement='bottom-start'
    />
  );
};

const GET_FIELD_VALUES = gql`
  query GetFieldValues(
    $fieldName: String!
    $searchEntity: String!
    $companyId: Int
  ) {
    getFieldValues(
      fieldName: $fieldName
      searchEntity: $searchEntity
      companyId: $companyId
    )
  }
`;

interface RecordingsSearchAndFiltersProps {
  entity: string;
  filters: RecordingsFilters;
  limit: number;
  offset: number;
  orderBy?: string;
  onLoadingChange: (isLoading: boolean) => void;
  onSearchResults: (results: any[]) => void;
  setFilters: Dispatch<SetStateAction<RecordingsFilters>>;
  setTotalItemsCount: (count: number) => void;
}

const RecordingsSearchAndFilters: React.FC<RecordingsSearchAndFiltersProps> = ({
  entity,
  filters,
  limit,
  offset,
  orderBy,
  onLoadingChange,
  onSearchResults,
  setFilters,
  setTotalItemsCount,
}) => {
  const { selectedCompany } = useCompany();
  const [userIds, setUserIds] = useState<string[]>([]);
  const [pageVisitUrls, setPageVisitUrls] = useState<string[]>([]); // New state for page visit URLs
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const [fetchUserIds, { data: userIdsData, loading: userIdsLoading }] =
    useLazyQuery(GET_FIELD_VALUES);

  const [
    fetchPageVisitUrls,
    { data: pageVisitUrlsData, loading: pageVisitUrlsLoading },
  ] = useLazyQuery(GET_FIELD_VALUES);

  const [searchUsingQuery] = useSearchUsingQueryLazyQuery();

  useEffect(() => {
    const variables = {
      searchEntity: entity,
      ...(selectedCompany && {
        companyId: parseInt(selectedCompany.value, 10),
      }),
    };

    // Fetch user IDs
    fetchUserIds({
      variables: {
        fieldName: "user_id",
        ...variables,
      },
    });

    // Fetch page visit URLs
    fetchPageVisitUrls({
      variables: {
        fieldName: "page_visits",
        ...variables,
      },
    });

    setFilters(RECORDINGS_EMPTY_FILTERS);
  }, [selectedCompany, fetchUserIds, fetchPageVisitUrls, entity, setFilters]);

  useEffect(() => {
    if (userIdsData) {
      setUserIds(userIdsData.getFieldValues);
    }
  }, [userIdsData]);

  useEffect(() => {
    if (pageVisitUrlsData) {
      setPageVisitUrls(pageVisitUrlsData.getFieldValues);
    }
  }, [pageVisitUrlsData]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      const { pageVisitUrls } = filters;
      const searchQuery = new SearchQuery();

      if (selectedUserId) {
        searchQuery.addSearchParameter("user_id", "==", selectedUserId);
      }
      if (pageVisitUrls.length) {
        pageVisitUrls.forEach((url) =>
          searchQuery.addSearchParameter(
            "page_visits__page_url__url",
            "==",
            url,
          ),
        );
      }

      const variables = {
        searchEntity: entity,
        searchQuery: searchQuery.toString(),
        limit,
        offset,
        companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
        orderBy,
      };

      onLoadingChange(true); // Start loading state
      try {
        const { data } = await searchUsingQuery({ variables });

        if (data) {
          const results = data.searchUsingQuery?.results.items || [];
          onSearchResults(results);
          setTotalItemsCount(
            data.searchUsingQuery?.results.totalItemsCount || 0,
          );
        }
      } catch (err) {
        console.error("Search failed", err);
      } finally {
        onLoadingChange(false); // End loading state
      }
    };

    fetchSearchResults(); // Fetch on variables change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedUserId,
    filters,
    entity,
    limit,
    offset,
    selectedCompany,
    orderBy,
  ]);

  const pageVisitUrlOptions = useMemo(
    () => pageVisitUrls.filter((url) => !filters.pageVisitUrls.includes(url)),
    [pageVisitUrls, filters.pageVisitUrls],
  );

  const filtered = !!(filters.pageVisitUrls.length || filters.devices.length);

  return (
    <div className='flex flex-col text-sm gap-3'>
      <Autocomplete
        id='user-name-autocomplete'
        size='small'
        forcePopupIcon={false}
        classes={{
          clearIndicator: "[&>svg]:text-xs",
          input: "!py-0.5 !px-0",
          inputRoot: "!text-xs !py-1.5 !pl-1.5 !pr-8",
          option: "!px-2",
        }}
        options={userIds}
        loading={userIdsLoading}
        value={selectedUserId}
        blurOnSelect
        onChange={(_, newValue) => setSelectedUserId(newValue || null)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='User Name'
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <FaSearch />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {userIdsLoading ? (
                    <CircularProgress color='inherit' size={16} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxComponent={
          VirtualizedListboxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        ListboxProps={{
          className: "!text-xs",
        }}
      />
      <div className='flex gap-1 font-semibold'>
        Filters
        {filtered && (
          <>
            {" "}
            -
            <div
              className='cursor-pointer'
              onClick={() => setFilters(RECORDINGS_EMPTY_FILTERS)}
              role='button'
              tabIndex={0}
            >
              clear all
            </div>
          </>
        )}
      </div>
      <div className='font-bold'>Page</div>
      <Autocomplete
        id='page-visit-url-autocomplete'
        forcePopupIcon={false}
        classes={{
          clearIndicator: "[&>svg]:text-xs",
          input: "!py-0.5 !px-0",
          inputRoot: "!text-xs !py-1.5 !pl-1.5 !pr-8",
          option: "!px-2",
        }}
        options={pageVisitUrlOptions}
        loading={pageVisitUrlsLoading}
        value={null}
        blurOnSelect
        onChange={(_, newValue) => {
          if (newValue) {
            setFilters((prev) => ({
              ...prev,
              pageVisitUrls: [...prev.pageVisitUrls, newValue],
            }));
          }
        }}
        PopperComponent={CustomPopper}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {pageVisitUrlsLoading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxComponent={
          VirtualizedListboxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
        ListboxProps={{
          className: "!text-xs",
        }}
      />
      {!!filters.pageVisitUrls.length && (
        <div className='flex flex-col gap-1'>
          {filters.pageVisitUrls.map((url) => (
            <div key={url} className='flex gap-1 items-center'>
              <p className='truncate min-w-0'>{url}</p>
              <MdClose
                size={16}
                className='cursor-pointer min-w-4'
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    pageVisitUrls: prev.pageVisitUrls.filter(
                      (value) => value !== url,
                    ),
                  }))
                }
              />
            </div>
          ))}
        </div>
      )}
      <div className='font-bold'>Device</div>
      <div className='flex flex-col gap-1'>
        <Checkbox
          key='desktop'
          label='Desktop'
          checked={filters.devices.includes("Desktop")}
          onChange={(checked) =>
            setFilters((prev) => ({
              ...prev,
              devices: checked
                ? [...prev.devices, "Desktop"]
                : prev.devices.filter((device) => device !== "Desktop"),
            }))
          }
          disabled={false}
          tooltip=''
          additionalClassNames='!h-3.5 !w-3.5'
        />
        <Checkbox
          key='mobile'
          label='Mobile'
          checked={filters.devices.includes("Mobile")}
          onChange={(checked) =>
            setFilters((prev) => ({
              ...prev,
              devices: checked
                ? [...prev.devices, "Mobile"]
                : prev.devices.filter((device) => device !== "Mobile"),
            }))
          }
          disabled={false}
          tooltip=''
          additionalClassNames='!h-3.5 !w-3.5'
        />
      </div>
    </div>
  );
};

export default RecordingsSearchAndFilters;

// Virtualized ListboxComponent for Autocomplete
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const element = data[index];

  return React.cloneElement(element, {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
    children: <p className='truncate'>{element.props.children}</p>,
  });
}

const LISTBOX_PADDING = 8; // Adjust for padding

const VirtualizedListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function VirtualizedListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 36;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  /*
    Important - this is required for the component to render!
  */
  return (
    // WE NEED THIS TO RENDER
    <div ref={ref} {...other}>
      <FixedSizeList
        height={getHeight() + LISTBOX_PADDING}
        width='100%'
        itemSize={itemSize}
        itemCount={itemCount}
        overscanCount={5}
        itemData={itemData}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});
