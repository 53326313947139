import Tooltip from "@mui/material/Tooltip";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaBriefcaseMedical } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCreateTriageMutation } from "../__generated__/graphql";
import ApplicationPage from "../components/ApplicationPage";
import CtaButton from "../components/Buttons/CtaButton";
import SearchBar from "../components/Search/SearchBar";
import Table from "../components/Table";
import { useCompany } from "../contexts/CompanyContext";

const DEBUG = import.meta.env.VITE_DEBUG ? import.meta.env.VITE_DEBUG : false;
const DEFAULT_RECORDINGS_PER_PAGE = 25;
const COLUMNS = [
  { id: "id", label: "Log ID" },
  {
    id: "timestampFormatted",
    label: "Date",
  },
  {
    id: "message",
    label: "Message",
  },
  {
    id: "trace",
    label: "Trace",
  },
  {
    id: "level",
    label: "Level",
  },
];

const LogsWithSearchAndExaminePage = () => {
  const { selectedCompany } = useCompany();
  const [page, setPage] = useState(0);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [logsPerPage, setLogsPerPage] = useState(DEFAULT_RECORDINGS_PER_PAGE);
  const [loading, setLoading] = useState(false); // State for loading
  const [notifiedAboutFakeIdSet, setNotifiedAboutFakeIdSet] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  // Ref to store previous results to avoid reprocessing the same results
  // State and refs
  const [consoleLogs, setConsoleLogs] = useState<any[]>([]);
  const previousResultsRef = useRef<any[]>([]);

  // Ref to control if handleSearchResults should be invoked on first render
  const isInitialRender = useRef(true);

  const [createTriage] = useCreateTriageMutation();

  const navigate = useNavigate();

  const handleSearchResults = useCallback((results: any[]) => {
    // Check if the results have changed from the previous ones using deep comparison
    if (
      JSON.stringify(previousResultsRef.current) === JSON.stringify(results)
    ) {
      return; // Exit if results are the same to prevent unnecessary re-processing
    }

    if (DEBUG) console.log("handleSearchResults", "results", results);

    // Convert each result to the LogItem format
    const logItems = results.map((result, index) => {
      const timestamp = result.data?.timestamp?.toString() || "";
      const timestampFormatted =
        result.data?.timestampFormatted?.toString() || "";
      // TODO - Sibyl post-launch - remove the in-line if and force to ID
      const id = result.data?.id ? result.data?.id : `${timestamp}-${index}`;
      if (!result.data?.id && !notifiedAboutFakeIdSet) {
        setNotifiedAboutFakeIdSet(true);
        if (DEBUG)
          console.log("Set ID on LogsWithSearchAndExaminePage to FakeID");
      }
      const message = result.data?.["data.payload.payload"]?.[0] || "";
      let recordingId: string = result.data?.["filename"];
      if (recordingId !== null) {
        recordingId = recordingId.replace(/^gs:\/\/[^/]+\/([^/]+)\/.*$/, "$1");
      }

      return {
        id,
        recordingId,
        timestampFormatted,
        message,
        trace: result.data?.["data.payload.trace"]?.join(", ") || "",
        level: result.data?.["data.payload.level"] || "",
      };
    });

    // Update the state with the converted log items
    setConsoleLogs(logItems);
    if (DEBUG) console.log("Converted Console Logs:", logItems);

    // Update the ref to the current results after processing
    previousResultsRef.current = results;
  }, []);

  const handleTotalItemsCount = (count: number) => {
    setTotalItemsCount(count); // Update the total items count
  };

  const handleCreateTriage = async () => {
    try {
      const triageInfoLogs = selected.reduce<any[]>((acc, logId) => {
        const { recordingId } = consoleLogs.find(({ id }) => id === logId);
        const triageInfoLog = acc.find(
          ({ recordingId: id }) => id === recordingId,
        );
        const logRecordId = logId.includes("-") ? logId.split("-")[0] : logId;
        if (triageInfoLog) {
          triageInfoLog.logRecordIds.push(logRecordId);
          return acc;
        }
        acc.push({ recordingId, logRecordIds: [logRecordId] });
        return acc;
      }, []);

      const response = await createTriage({
        variables: {
          triageInfoLogs,
          ...(selectedCompany && {
            companyId: parseInt(selectedCompany.value, 10),
          }),
        },
      });
      if (response.data?.createTriage) {
        navigate(`/triage/${response.data.createTriage.id}`);
      }
    } catch (error) {
      console.error("Error creating triage:", error);
    }
  };

  // Effect to manage initial render and reset states when necessary
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false; // Mark initial render as handled
      return;
    }

    // Reset previous results when necessary
    previousResultsRef.current = [];
  }, [consoleLogs, loading]); // Runs when `consoleLogs` or `loading` state changes

  return (
    <ApplicationPage pageName='Logs'>
      <div className='h-[calc(100%-44px)] flex flex-col gap-4'>
        <SearchBar
          entity='consoleLogs'
          limit={logsPerPage}
          offset={page * logsPerPage}
          onSearchResults={handleSearchResults}
          setTotalItemsCount={handleTotalItemsCount}
          onLoadingChange={setLoading}
        />
        <div className='h-[calc(100%-140px)] flex flex-col gap-2'>
          <div className='flex justify-end'>
            <Tooltip
              title={selected.length ? "" : "Select logs from list to Triage"}
              placement='top'
            >
              <div>
                <CtaButton
                  titlePrimary='Triage'
                  icon={<FaBriefcaseMedical />}
                  bgPrimaryClass='bg-primary-blue-100'
                  textPrimaryClass='text-white'
                  bgPrimaryHoverClass='hover:bg-primary-blue-100'
                  textPrimaryHoverClass='hover:text-white'
                  onClick={handleCreateTriage}
                  showOutlineHoverPrimary={false}
                  additionalClassNames='h-8 rounded-md text-sm leading-6 font-medium shadow-md'
                  additionalWidth='24px'
                  disabled={!selected.length}
                  startIcon
                />
              </div>
            </Tooltip>
          </div>
          <div className='h-[calc(100%-40px)]'>
            <Table
              checkboxSelection
              columns={COLUMNS}
              count={totalItemsCount}
              idKey='id'
              loading={loading}
              page={page}
              rows={consoleLogs}
              rowsPerPage={logsPerPage}
              handleChangeRowsPerPage={setLogsPerPage}
              setPage={setPage}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
      </div>
    </ApplicationPage>
  );
};

export default LogsWithSearchAndExaminePage;
