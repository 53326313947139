export const APP_ROOT_DOMAIN = `${window.location.protocol}//${window.location.host}`;

export const LINEAR_PRIORITIES = [
  { value: 0, name: "No Priority" },
  { value: 1, name: "Urgent" },
  { value: 2, name: "High" },
  { value: 3, name: "Medium" },
  { value: 4, name: "Low" },
];

// TODO fetch from backend and integration
export const LINEAR_STATUSES = [
  { value: 1, name: "Backlog" },
  { value: 2, name: "Todo" },
  { value: 3, name: "In Progress" },
  { value: 4, name: "In Review" },
  { value: 5, name: "Done" },
  { value: 6, name: "Canceled" },
  { value: 7, name: "Duplicate" },
];

const GITHUB_REDIRECT_URI = `${APP_ROOT_DOMAIN}/settings/integrations/github`;
const LINEAR_REDIRECT_URI = `${APP_ROOT_DOMAIN}/settings/integrations/linear`;
const ZENDESK_REDIRECT_URI = `${APP_ROOT_DOMAIN}/settings/integrations/zendesk`;

// sailfish-ai-test - dev
// sailfish-ai - prod
const githubAppName =
  process.env.NODE_ENV === "production" ? "sailfish-ai" : "sailfish-ai-test";

export const INTEGRATIONS = {
  GITHUB: "GitHub",
  GITHUB_APP_INSTALLATION_URL: `https://github.com/apps/${githubAppName}/installations/new`,
  GITHUB_REDIRECT_URI,
  GITHUB_INTEGRATIONS_PATH: "/settings/integrations#github",
  LINEAR: "Linear",
  LINEAR_REDIRECT_URI,
  LINEAR_INTEGRATIONS_PATH: "/settings/integrations#linear",
  LINEAR_OAUTH_URI: `https://linear.app/oauth/authorize?response_type=code&redirect_uri=${LINEAR_REDIRECT_URI}&state=tLt4jwm5rarjIbg4TVykDceQrE1sfuu2&scope=read,write,admin&actor=application`,
  ZENDESK: "Zendesk",
  ZENDESK_REDIRECT_URI,
  ZENDESK_INTEGRATIONS_PATH: "/settings/integrations#zendesk",
  getZendeskOauthUri: (subdomain: string) =>
    `https://${subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&redirect_uri=${ZENDESK_REDIRECT_URI}&scope=tickets%3Aread%20write`,
};

export const zendeskApiKey = import.meta.env.VITE_ZENDESK_API_KEY;

export const RECORDINGS_EMPTY_FILTERS = { pageVisitUrls: [], devices: [] };

export const DEVICE_TYPES = {
  DESKTOP: "Desktop",
  MOBILE: "Mobile",
} as const;
