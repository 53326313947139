import React from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaBriefcaseMedical, FaStethoscope } from "react-icons/fa";
import IconButton from "./IconButton";
import CtaButton from "./Buttons/CtaButton";

interface VideoPlayerTriageProps {
  disabled: boolean;
  displayOnly: boolean;
  handleCreateTriage: () => void;
  toggleDisplayMode: () => void;
}

const VideoPlayerTriage: React.FC<VideoPlayerTriageProps> = ({
  disabled,
  displayOnly,
  handleCreateTriage,
  toggleDisplayMode,
}) => {
  return (
    <div className='flex-1 flex justify-center gap-2 ml-2'>
      <CtaButton
        titlePrimary='Examine'
        titleSecondary='Quit'
        iconPrimary={<FaStethoscope />}
        bgPrimaryClass='bg-green-600'
        textPrimaryClass='text-white'
        bgPrimaryHoverClass='hover:bg-green-600'
        textPrimaryHoverClass='hover:text-white'
        bgSecondaryClass='bg-white'
        textSecondaryClass='text-red-500'
        bgSecondaryHoverClass='hover:bg-white'
        textSecondaryHoverClass='hover:text-red-500'
        onClick={toggleDisplayMode}
        isPrimary={displayOnly}
        showOutlineHoverPrimary={false}
        showOutlineHoverSecondary={false}
        additionalClassNames={`h-8 rounded-md text-sm leading-6 font-medium shadow-md ${
          displayOnly ? "" : " border border-red-500"
        }`}
        additionalWidth='24px'
        disabled={disabled}
        startIcon
      />
      <CtaButton
        titlePrimary='Triage'
        icon={<FaBriefcaseMedical />}
        bgPrimaryClass='bg-primary-blue-100'
        textPrimaryClass='text-white'
        bgPrimaryHoverClass='hover:bg-primary-blue-100'
        textPrimaryHoverClass='hover:text-white'
        onClick={handleCreateTriage}
        showOutlineHoverPrimary={false}
        additionalClassNames='h-8 rounded-md text-sm leading-6 font-medium shadow-md'
        additionalWidth='24px'
        disabled={displayOnly}
        startIcon
      />
      <NavLink
        to='https://docs.sailfishqa.com/'
        className='link'
        target='_blank'
      >
        <IconButton
          size='text-lg'
          icon={AiOutlineQuestionCircle}
          disabled={disabled}
          tooltip='Click here to learn about Examine & Triage.'
          noShadow
        />
      </NavLink>
    </div>
  );
};

export default VideoPlayerTriage;
