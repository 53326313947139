import { DateTime } from "luxon";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IconType } from "react-icons";
import { FaChevronRight, FaTv } from "react-icons/fa6";
import { FiSmartphone } from "react-icons/fi";
import { RiPlayLine, RiStopLine } from "react-icons/ri";
import { RecordingSessionType } from "../__generated__/graphql";
import { DEVICE_TYPES } from "../utils/constants";
import { DeviceType } from "../utils/interfaces";
import RecordingsTimeline, { Region } from "./RecordingsTimeline";
import TimePointDisplayIndividual from "./TimePointDisplayIndividual";
import VideoPlayerRuler from "./VideoPlayerRuler";

const DEFAULT_REGION = { START: 0.35, END: 0.65 };

interface Device {
  device: DeviceType;
  Icon: IconType;
  recordings: RecordingSessionType[];
}

interface GroupedRecordingsTimelineProps {
  currentPosition?: number;
  currentSection?: [number, number] | null;
  displayOnly?: boolean;
  enhanced?: boolean;
  hoverTime?: number | null;
  recordings: RecordingSessionType[];
  selected?: DeviceType | false | null;
  selectedRegion?: Region | null;
  timeline: [number, number];
  onPlay: (device: DeviceType, recordings: RecordingSessionType[]) => void;
  onTimelineClick?: (recording: RecordingSessionType, time: number) => void;
  setSelectedRegion?: Dispatch<SetStateAction<Region | null>>;
}

const GroupedRecordingsTimeline: React.FC<GroupedRecordingsTimelineProps> = ({
  currentPosition,
  currentSection,
  displayOnly,
  enhanced,
  hoverTime,
  recordings,
  selected,
  selectedRegion,
  timeline,
  onPlay,
  onTimelineClick,
  setSelectedRegion,
}) => {
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const startXRef = useRef(0);
  const offsetStartRef = useRef(0);
  const isMoving = useRef(false);

  const desktopRecordings = useMemo(
    () => recordings.filter((r) => r.metadata?.device?.isDesktop),
    [recordings],
  );
  const mobileRecordings = useMemo(
    () => recordings.filter((r) => r.metadata?.device?.isMobile),
    [recordings],
  );

  const [visibleStart, visibleEnd] = useMemo(() => {
    if (!enhanced || !containerRef.current || scale === 1) return timeline;

    const containerWidth = containerRef.current.offsetWidth - 48;
    const totalMs = timeline[1] - timeline[0];
    const visibleMs = totalMs / scale;
    const msPerPixel = visibleMs / containerWidth;
    const centerShiftMs = offset * msPerPixel;
    const centerTime = timeline[0] + visibleMs / 2 - centerShiftMs;

    return [centerTime - visibleMs / 2, centerTime + visibleMs / 2];
  }, [enhanced, offset, scale, timeline]);

  // Auto-select a region when displayOnly becomes false
  useEffect(() => {
    if (!displayOnly && setSelectedRegion && visibleStart && visibleEnd) {
      const regionStart =
        visibleStart + (visibleEnd - visibleStart) * DEFAULT_REGION.START;
      const regionEnd =
        visibleStart + (visibleEnd - visibleStart) * DEFAULT_REGION.END;
      setSelectedRegion({ start: regionStart, end: regionEnd });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayOnly]);

  // Reset zoom on recordings change
  useEffect(() => {
    if (scale !== 1) {
      setScale(1);
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordings]);

  // Handle zoom
  useEffect(() => {
    const handleZoomKey = (e: KeyboardEvent) => {
      if (!enhanced || !displayOnly) return;
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.key === "+" || e.key === "=" || e.key === "-")
      ) {
        e.preventDefault();
        const direction = e.key === "-" ? "out" : "in";
        handleZoomDirection(direction);
      }
    };

    const handleWheel = (e: WheelEvent) => {
      if (!enhanced || !displayOnly) return;
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        const direction = e.deltaY > 0 ? "out" : "in";
        handleZoomDirection(direction, 1.01);
      }
    };

    const handleZoomDirection = (direction: "in" | "out", multiplier = 2) => {
      setScale((prevScale) => {
        const container = containerRef.current;
        if (!container) return prevScale;

        const newScale =
          direction === "out"
            ? Math.max(1, prevScale / multiplier)
            : Math.min(2048, prevScale * multiplier);

        if (newScale === prevScale) return newScale;

        const m =
          direction === "out" ? prevScale / newScale : newScale / prevScale;

        const containerWidth = container.offsetWidth - 48;
        const maxOffset = (newScale - 1) * containerWidth;

        setOffset((prevOffset) => {
          const newOffset =
            direction === "out"
              ? (prevOffset + (containerWidth * (m - 1)) / 2) / m
              : m * prevOffset - (containerWidth * (m - 1)) / 2;
          return Math.max(-maxOffset, Math.min(newOffset, 0));
        });

        return newScale;
      });
    };

    window.addEventListener("keydown", handleZoomKey);
    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("keydown", handleZoomKey);
      window.removeEventListener("wheel", handleWheel);
    };
  }, [enhanced, displayOnly]);

  // Handle timeline drag
  useEffect(() => {
    const elements = Object.values(contentRefs.current);
    if (!enhanced || scale === 1 || elements.length === 0 || !displayOnly)
      return;

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
      startXRef.current = e.clientX;
      offsetStartRef.current = offset;
    };

    elements.forEach((el) =>
      el?.addEventListener("mousedown", handleMouseDown),
    );
    return () => {
      elements.forEach((el) =>
        el?.removeEventListener("mousedown", handleMouseDown),
      );
    };
  }, [enhanced, scale, offset, displayOnly]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      isMoving.current = true;
      const delta = e.clientX - startXRef.current;
      const containerWidth = containerRef.current?.offsetWidth
        ? containerRef.current.offsetWidth - 48
        : 0;
      const newOffset = offsetStartRef.current + delta;
      const maxOffset = (scale - 1) * containerWidth;
      setOffset(Math.max(-maxOffset, Math.min(newOffset, 0)));
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      // Use a timeout so at first onTimelineClick happen then isMoving is marked as false
      setTimeout(() => {
        isMoving.current = false;
      }, 0);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, scale]);

  const formatTimestamp = (ts: number) => {
    const dt = DateTime.fromMillis(ts);
    return {
      date: dt.toFormat("LLL/dd/yyyy"),
      time: dt.toFormat("HH:mm:ss"),
    };
  };

  const start = formatTimestamp(visibleStart);
  const end = formatTimestamp(visibleEnd);

  const devices: Device[] = [
    { device: DEVICE_TYPES.DESKTOP, Icon: FaTv, recordings: desktopRecordings },
    {
      device: DEVICE_TYPES.MOBILE,
      Icon: FiSmartphone,
      recordings: mobileRecordings,
    },
  ];

  return (
    <div className='flex flex-col flex-1 gap-0.5 text-xs' ref={containerRef}>
      {devices.map(({ device, Icon, recordings }) => (
        <div
          key={device}
          className={`flex gap-1 ${
            enhanced && selected === device ? "h-10" : "h-3"
          }`}
        >
          {selected === device ? (
            <RiStopLine className='text-primary-blue-100 border border-sky-200 rounded' />
          ) : (
            <RiPlayLine
              className={`border rounded ${
                recordings.length
                  ? "text-primary-blue-100 border-sky-200 cursor-pointer"
                  : "text-zinc-200 border-zinc-200"
              }`}
              onClick={() => recordings.length && onPlay(device, recordings)}
            />
          )}
          <Icon className='text-zinc-500' />
          <FaChevronRight className='text-zinc-500' />
          <RecordingsTimeline
            recordings={recordings}
            timeline={timeline}
            currentPosition={
              enhanced && selected === device ? currentPosition : 0
            }
            onTimelineClick={onTimelineClick}
            scale={scale}
            offset={offset}
            isDraggable={enhanced && scale > 1 && displayOnly}
            isMoving={isMoving.current}
            contentRef={(el) => (contentRefs.current[device] = el)}
            displayOnly={displayOnly}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            hoverTime={hoverTime}
            currentSection={currentSection}
          />
        </div>
      ))}

      {enhanced && !!containerRef.current?.offsetWidth && (
        <div className='ml-12'>
          <TimePointDisplayIndividual
            containerWidth={containerRef.current.offsetWidth - 48}
            videoDurationSeconds={(timeline[1] - timeline[0]) / 1000 / scale}
          />
          <VideoPlayerRuler
            containerWidth={containerRef.current.offsetWidth - 48}
          />
          <div className='flex justify-between text-xs mt-1'>
            <div className='text-left'>
              <div>{start.date}</div>
              <div>{start.time}</div>
            </div>
            <div className='text-right'>
              <div>{end.date}</div>
              <div>{end.time}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupedRecordingsTimeline;
