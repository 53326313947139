import { Autocomplete, Box, Modal, Switch, TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  EditEngineeringTicketPlatformIntegrationInput,
  useGetEngineeringTicketPlatformIntegrationConfigsQuery,
  useGetEngineeringTicketPlatformIntegrationsQuery,
  useGetGithubIntegrationsQuery,
  useUpdateEngineeringTicketPlatformIntegrationMutation,
} from "../../__generated__/graphql";
import { useCompany } from "../../contexts/CompanyContext";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";
import GitHubLogo from "../../images/githubLogo.png";
import LinearLogo from "../../images/linearLogo.png";
import ZendeskLogo from "../../images/zendeskLogo.png";
import { INTEGRATIONS, LINEAR_PRIORITIES } from "../../utils/constants";
import {
  AutocompleteOption,
  SettingsComponentProps,
} from "../../utils/interfaces";
import ActionBanner from "../ActionBanner";
import IntegrationButton from "../IntegrationButton";
import LoadingAnimation from "../LoadingAnimation";
import Section from "./Section";

const {
  GITHUB,
  GITHUB_APP_INSTALLATION_URL,
  LINEAR,
  LINEAR_OAUTH_URI,
  ZENDESK,
  getZendeskOauthUri,
} = INTEGRATIONS;

type IntegrationSettings = Omit<
  EditEngineeringTicketPlatformIntegrationInput,
  "provider"
>;

const TAB_NAME = "integrations";

const Integrations: React.FC<SettingsComponentProps> = ({ section }) => {
  const [zendeskSubdomain, setZendeskSubdomain] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
  const { data, loading, error, refetch } =
    useGetEngineeringTicketPlatformIntegrationsQuery({
      variables: {
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });

  const {
    data: dataGithubIntegrations,
    loading: loadingGithubIntegrations,
    error: errorGithubIntegrations,
  } = useGetGithubIntegrationsQuery({
    variables: {
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
  });

  const linearData = data?.getEngineeringTicketPlatformIntegrations?.find(
    (item) => item?.provider === LINEAR,
  );
  const zendeskData = data?.getEngineeringTicketPlatformIntegrations?.find(
    (item) => item?.provider === ZENDESK,
  );

  const { data: integrationConfigsData } =
    useGetEngineeringTicketPlatformIntegrationConfigsQuery({
      variables: {
        companyId: selectedCompany
          ? parseInt(selectedCompany.value, 10)
          : undefined,
      },
    });

  const linearConfigs =
    integrationConfigsData?.getEngineeringTicketPlatformIntegrationConfigs?.find(
      (item) => item?.provider === LINEAR,
    );
  const zendeskConfigs =
    integrationConfigsData?.getEngineeringTicketPlatformIntegrationConfigs?.find(
      (item) => item?.provider === ZENDESK,
    );

  const [settings, setSettings] = useState<IntegrationSettings>({
    pushAutoIdentifiedIssues: false,
    defaultPriority: 0,
    defaultProject: "",
    defaultTeam: "",
  });
  const originalSettingsRef = useRef<IntegrationSettings>(settings);

  const [updateIntegrations] =
    useUpdateEngineeringTicketPlatformIntegrationMutation();
  const {
    isDirty,
    setExternalSaveFunction,
    markSettingAsChanged,
    clearUnsavedChanges,
  } = useUnsavedChanges();

  const linearProjects: AutocompleteOption[] = linearData?.projects || [];
  const linearTeams: AutocompleteOption[] = linearData?.teams || [];
  const linearInstalled = linearData?.installed;
  const linearClientId = linearConfigs?.clientId;

  const zendeskInstalled = zendeskData?.installed;
  const zendeskClientId = zendeskConfigs?.clientId;
  const githubInstalled =
    dataGithubIntegrations?.getGithubIntegrations.length! > 0;

  useEffect(() => {
    if (linearData) {
      const {
        installed,
        pushAutoIdentifiedIssues,
        defaultPriority,
        defaultProject,
        defaultTeam,
      } = linearData;
      if (!installed) {
        return;
      }

      const linearSettings = {
        pushAutoIdentifiedIssues,
        defaultPriority,
        defaultProject,
        defaultTeam,
      };
      setSettings(linearSettings);
      originalSettingsRef.current = linearSettings;
    }
  }, [linearData]);

  // Refetch the query whenever the selectedCompany changes
  useEffect(() => {
    refetch({
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    });
  }, [selectedCompany, refetch]);

  const handleInputChange = useCallback(
    (field: keyof IntegrationSettings, value: any) => {
      setSettings((prevSettings) => {
        const newSettings = { ...prevSettings, [field]: value };
        if (prevSettings[field] !== value) {
          markSettingAsChanged(TAB_NAME);
        }
        return newSettings;
      });
    },
    [markSettingAsChanged],
  );

  const handleSaveChanges = useCallback(async () => {
    if (isDirty(TAB_NAME)) {
      try {
        const input = {
          provider: LINEAR,
          ...settings,
        };

        await updateIntegrations({
          variables: {
            input, // Always provide the required input
            companyId: selectedCompany
              ? parseInt(selectedCompany.value, 10)
              : undefined, // Company ID is optional
          },
        });

        originalSettingsRef.current = settings;
        clearUnsavedChanges(TAB_NAME);
      } catch (error) {
        console.error("Error updating integrations:", error);
      }
    }
  }, [
    isDirty,
    settings,
    clearUnsavedChanges,
    updateIntegrations,
    selectedCompany,
  ]);

  useEffect(() => {
    setExternalSaveFunction(TAB_NAME, handleSaveChanges);
  }, [handleSaveChanges, setExternalSaveFunction]);

  const handleDiscardChanges = useCallback(() => {
    setSettings(originalSettingsRef.current);
    clearUnsavedChanges(TAB_NAME);
  }, [clearUnsavedChanges]);

  const storeZendeskSubdomainInLocalStorage = () =>
    localStorage.setItem("zendeskSubdomain", zendeskSubdomain);

  return (
    <>
      {loading ? (
        <LoadingAnimation text='Loading...' />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <>
          <Section title={LINEAR} hide={section !== "linear"}>
            {linearInstalled ? (
              <div className='space-y-4'>
                <div className='flex items-center font-medium'>
                  <Switch
                    checked={settings.pushAutoIdentifiedIssues || false}
                    onChange={(event, checked) =>
                      handleInputChange("pushAutoIdentifiedIssues", checked)
                    }
                  />
                  <p>
                    Push auto-identified issues in Sailfish to Engineering
                    Ticketing platform
                  </p>
                </div>
                <Autocomplete
                  options={linearTeams}
                  getOptionLabel={(option) => option.name}
                  value={
                    linearTeams.find(({ id }) => id === settings.defaultTeam) ||
                    null
                  }
                  onChange={(event, value) =>
                    handleInputChange("defaultTeam", value?.id || "")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Default Team'
                      variant='outlined'
                    />
                  )}
                />
                <Autocomplete
                  options={linearProjects}
                  getOptionLabel={(option) => option.name}
                  value={
                    linearProjects.find(
                      ({ id }) => id === settings.defaultProject,
                    ) || null
                  }
                  onChange={(event, value) =>
                    handleInputChange("defaultProject", value?.id || "")
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Default Project'
                      variant='outlined'
                    />
                  )}
                />
                <Autocomplete
                  disableClearable
                  options={LINEAR_PRIORITIES}
                  getOptionLabel={(option) => option.name} // Maps `name` for display
                  value={LINEAR_PRIORITIES.find(
                    ({ value }) => value === settings.defaultPriority,
                  )}
                  onChange={(event, newValue) =>
                    handleInputChange("defaultPriority", newValue?.value || 0)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  } // Compares `value`
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Default Priority'
                      variant='outlined'
                    />
                  )}
                />
              </div>
            ) : (
              <div className='flex items-center'>
                <IntegrationButton
                  logo={LinearLogo}
                  name={LINEAR}
                  to={`${LINEAR_OAUTH_URI}&client_id=${linearClientId}`}
                />
              </div>
            )}
          </Section>
          <Section title={GITHUB} hide={section !== "github"}>
            {/* TODO - remove below once we have LLM service(s) in place */}
            <div className='text-3xl text-red-500 font-bold mb-4'>
              Pre-release
            </div>
            {githubInstalled ? (
              <div className='space-y-4'>
                {dataGithubIntegrations?.getGithubIntegrations.map(
                  (integration) => (
                    <IntegrationButton
                      key={integration.installationId}
                      logo={GitHubLogo}
                      // Display the organization name along with GitHub label.
                      name={`${integration.installedOnAccount}`}
                      nameTextPreamble='Edit installation for organization:'
                      // Link to a page for managing this particular integration.
                      to={`https://github.com/organizations/SailfishAI/settings/installations/${integration.installationId}`}
                    />
                  ),
                )}
                <br />
                {/* Optionally, a button to add more installations */}
                <IntegrationButton
                  logo={GitHubLogo}
                  nameTextPreamble=''
                  name='Add more GitHub organizations'
                  to={GITHUB_APP_INSTALLATION_URL}
                />
              </div>
            ) : (
              <div className='flex items-center'>
                <IntegrationButton
                  logo={GitHubLogo}
                  name={GITHUB}
                  to={GITHUB_APP_INSTALLATION_URL}
                />
              </div>
            )}
          </Section>
          <Section title={ZENDESK} hide={section !== "zendesk"}>
            {zendeskInstalled ? (
              <div className='space-y-4'>Zendesk Integrated!!!</div>
            ) : (
              <div className='flex items-center'>
                <IntegrationButton
                  logo={ZendeskLogo}
                  name={ZENDESK}
                  to={window.location.href}
                  onClick={() => setIsModalOpen(true)}
                />
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                  <Box
                    sx={{
                      p: 4,
                      backgroundColor: "white",
                      margin: "auto",
                      borderRadius: "8px",
                      width: "400px",
                      marginTop: "320px",
                    }}
                  >
                    <h2 className='text-primary-blue-100 font-bold mb-2'>
                      Please specify your Zendesk subdomain
                    </h2>
                    <TextField
                      label='Subdomain'
                      fullWidth
                      value={zendeskSubdomain}
                      onChange={(e) => setZendeskSubdomain(e.target.value)}
                      required
                      sx={{ marginBottom: "10px" }}
                    />
                    {/* Zendesk integration */}
                    <NavLink
                      to={`${getZendeskOauthUri(
                        zendeskSubdomain,
                      )}&client_id=${zendeskClientId}`}
                      className='link'
                      target='_blank'
                    >
                      <button
                        className='flex items-center bg-sky-100 text-primary-blue-100 px-4 py-2 shadow rounded-md gap-1 text-sm font-medium'
                        onClick={() => {
                          storeZendeskSubdomainInLocalStorage();
                          setIsModalOpen(false);
                        }}
                      >
                        Connect integration
                      </button>
                    </NavLink>
                  </Box>
                </Modal>
              </div>
            )}
          </Section>

          {isDirty(TAB_NAME) && (
            <ActionBanner
              onSave={() => handleSaveChanges()}
              onDiscard={() => handleDiscardChanges()}
            />
          )}
        </>
      )}
    </>
  );
};

export default Integrations;
