import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface WebPlayerContextType {
  currentSection: [number, number] | null;
  hoverTime: number | null;
  setCurrentSection: Dispatch<SetStateAction<[number, number] | null>>;
  setHoverTime: Dispatch<SetStateAction<number | null>>;
}

const WebPlayerContext = createContext<WebPlayerContextType | undefined>(
  undefined,
);

export const useWebPlayer = () => {
  const context = useContext(WebPlayerContext);
  if (!context) {
    throw new Error("useWebPlayer must be used within an WebPlayerProvider");
  }
  return context;
};

interface WebPlayerProviderProps {
  children: React.ReactNode;
}

export const WebPlayerProvider: React.FC<WebPlayerProviderProps> = ({
  children,
}) => {
  const [hoverTime, setHoverTime] = useState<number | null>(null);
  const [currentSection, setCurrentSection] = useState<[number, number] | null>(
    null,
  );

  return (
    <WebPlayerContext.Provider
      value={{
        currentSection,
        hoverTime,
        setCurrentSection,
        setHoverTime,
      }}
    >
      {children}
    </WebPlayerContext.Provider>
  );
};
