import React from "react";
import { NavLink } from "react-router-dom";
import { RecordingSessionType } from "../../__generated__/graphql";
import Tabs from "../Tabs";
import Logs from "./Logs";

interface RecordingSummaryProps {
  recording: RecordingSessionType | null;
  seekToTime: (timeStamp: string) => void;
}

const RecordingSummary: React.FC<RecordingSummaryProps> = ({
  recording,
  seekToTime,
}) => {
  const tabs = [
    {
      title: "Logs & Exceptions",
      content: () =>
        recording?.consoleLogs?.length ? (
          <Logs recording={recording} seekToTime={seekToTime} />
        ) : (
          <div className='flex flex-col text-center h-full items-center justify-center text-sm font-medium'>
            <div>😬 It looks like there are no logs to display yet.</div>
            <div className='flex'>
              Check back later or
              <NavLink
                to='https://docs.sailfishqa.com/'
                className='text-primary-blue-100 px-1'
                target='_blank'
              >
                click here
              </NavLink>
              to learn more.
            </div>
          </div>
        ),
    },
    {
      title: "AI Summary",
      content: () => (
        <div className='flex flex-col text-center h-full items-center justify-center text-sm font-medium'>
          <div>🥳 Big news! AI Summary is on its way, stay tuned!</div>
          <NavLink
            to='https://docs.sailfishqa.com/'
            className='text-primary-blue-100'
            target='_blank'
          >
            Learn more
          </NavLink>
        </div>
      ),
    },
  ];

  return (
    <div className='h-96'>
      <Tabs items={tabs} />
    </div>
  );
};

export default RecordingSummary;
